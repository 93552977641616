@import '~/styles/variables';

.container {
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: row;
  gap: 24px;
}

.velocityContainer {
  width: fit-content;
  min-width: fit-content;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.velocityTitle {
  color: $darkThemeDark2Text;
  text-align: center;
  font-size: 14px;
}
.velocitySlider {
  flex: 1;
}

.voicingContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.voicingHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
.chordOctave {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.octaveTitle {
  font-weight: 500;
  font-size: 14px;
  color: $darkThemeDark2Text;
  white-space: nowrap;
}
.chordDetails {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.chordDetailsName {
  color: $darkThemeText;
  font-size: 40px;
  font-weight: 500;
}
.chordDetailsDegree {
  color: $darkThemeDark2Text;
  font-weight: 400;
  font-size: 18px;
}
