@import '~/styles/variables';

.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0px 16px;
  user-select: none;
  font-weight: 600;
  @include mobile {
    height: 100%;
    width: 100%;
    background-color: $secondaryGray3;
    margin: 0;
    border-radius: 8px;
    justify-content: center;
  }
  .value {
    color: $darkThemeText;
    font-size: 18px;
    padding-bottom: 2px;
    user-select: none;
    @include bigDesktop {
      font-size: 22px;
    }
  }

  .name {
    @include transition(color);
    color: rgba($darkThemeDark2Text, 0.6);
    font-size: 12px;
    padding-bottom: 2px;
    user-select: none;
    @include bigDesktop {
      font-size: 14px;
    }
  }

  &:hover {
    .name {
      color: $darkThemeText;
    }
  }
}
