@import '~/styles/variables';

.modal {
  height: fit-content;
  width: fit-content !important;
}

.modalContent {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 312px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  padding: 40px 24px;

  background: $darkTheme;
  border-radius: 12px;
  border: 1px solid $secondaryGray;

  .innerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      width: 80px;
      height: 80px;
      border-radius: 80px;

      display: flex;
      justify-content: center;
      align-items: center;

      &.success {
        background: #18d28f33;
      }

      &.error {
        background: #e94f6633;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      text-align: center;
      color: $darkThemeText;
      margin-top: 16px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: $secondaryGray16;
      margin-top: 8px;
    }
  }

  .btn {
    width: 100%;
    padding: 13px 0;
    border-radius: 100px;
    @include gradientBgTransition(#3d4ff6, #c145ed);

    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    color: $darkThemeText;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
