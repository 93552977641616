@import '~/styles/variables';

// PreviousChord

.previousChord {
  width: 80px;
  height: 72px;

  padding: 0 8px;

  border: 1px solid $dark30;
  border-radius: 6px;
  cursor: pointer;
  @include transition(all);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;

  &:not(.previousChordDisabled):hover {
    border-color: $grey30;
  }

  @include smallTablet {
    display: none;
  }
}
.previousChordDisabled {
  opacity: 0.4;
  cursor: default;
}
.previousChordName {
  width: 100%;
  height: 20px;

  font-family: Quicksand;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: end;

  color: $darkThemeText;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.previousChordIcon {
  width: 18px;

  path {
    color: $secondaryGray38;
  }
}
.previousChordText {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: $secondaryGray36;
}

// ChordsGrid

.chordGridContainerTitle {
  position: relative;

  flex: 1;
  width: 100%;
  height: 100%;

  border-radius: 10px;
  border: 1px solid $dark30;
}
.chordGridContainer {
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  @include scroll-bar();
}
.chordGridContainerTitleMode {
  position: relative;
  padding: 16px;
}
.chordGridTitle {
  position: absolute;
  top: 0;
  left: 8px;
  transform: translateY(-50%);

  padding: 0 8px;

  font-family: Quicksand;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: 0%;
  text-align: center;
  color: $secondaryGray36;

  background: $darkTheme;
  @include transition(all);
}
.chordGridInnerContainer {
  position: relative;

  width: 100%;
  height: fit-content;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(112px, 1fr));
}

.chordGridContainerClosable {
  position: relative;

  flex: 1;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .chordGridContainerTitleMode {
    padding: 0;
  }

  .chordGridContainer {
    height: fit-content;
    overflow: visible;
  }

  @include mobile {
    flex: unset;
    height: fit-content;
  }
}
.chordGridContainerClosableTitleContainer {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  .chordGridContainerClosableTitleIcon {
    width: 16px;
    @include transition(all);

    path {
      color: $secondaryGray36;
      @include transition(all);
    }
  }

  .chordGridTitle {
    position: relative;
    padding: 0;
    top: unset;
    left: unset;
    transform: unset;

    margin: 0 8px 0 2px;
  }
}
.chordGridContainerClosableTitleLine {
  flex: 1;
  height: 1px;
  background: $dark30;
}
.chordGridContainerClosableContentContainer {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  @include transition(all);
}
.chordGridContainerClosableContentContent {
  min-height: 0;
  transition: visibility 1s;
  visibility: hidden;
}
.chordGridContainerClosableOpened {
  .chordGridTitle,
  .chordGridContainerClosableTitleIcon path {
    color: $darkThemeText;
  }
  .chordGridContainerClosableTitleIcon {
    transform: rotate(-90deg);
  }
  .chordGridContainerClosableContentContainer {
    grid-template-rows: 1fr;
  }
  .chordGridContainerClosableContentContent {
    visibility: visible;
  }
}

.chordGridChord {
  height: 72px;

  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;

  background: $dark20;
  border: 1px solid $dark30;
  border-radius: 6px;
  cursor: pointer;

  @include bgTransition($gray20, $gray25);
}
.chordGridChordName {
  font-family: Quicksand;
  font-weight: 700;
  font-size: 18px;
  line-height: 22.5px;
  text-align: center;

  color: $darkThemeText;
}
.chordGridChordDegree {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: $darkThemeText;
}
.chordGridChord.active {
  @include gradientBgTransition(#3d4ff7, #457eed);
}
.anotherChordTop {
  border-top: none;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.anotherChordLeft {
  border-left: none;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.anotherChordRight {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.anotherChordBottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.chordGridChordBlocked {
  width: 16px !important;
  height: 16px !important;

  top: 4px !important;
  right: 4px !important;
}
.chordGridChordInKey,
.chordGridChordNotInKey {
  width: 16px;
  height: 16px;

  position: absolute;
  top: 4px;
  right: 4px;

  border-radius: 50%;
  cursor: pointer;

  &::before {
    content: '';

    width: 8px;
    height: 8px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 50%;
  }
}
.chordGridChordInKey {
  background: #31453e;

  &::before {
    background: #0abb7b;
  }
}
.chordGridChordNotInKey {
  background: #4e2f33;

  &::before {
    background: #e94f66;
  }
}
.chordGridChordTooltip {
  padding: 0;

  background: $darkTheme;
  border: 1px solid $dark30;

  & > div:nth-last-child(1) {
    border: 1px solid transparent;

    border-bottom: 1px solid $dark30;
    border-right: 1px solid $dark30;

    transform: rotate(45deg);
  }
}
.chordGridChordTooltipContent {
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 6px;

  & > span {
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    color: $secondaryGray35;
  }
}
.chordGridChordTooltipNotInKeyHighlight {
  color: $secondaryRed;
}
.chordGridChordTooltipInKeyHighlight {
  color: $secondaryGreen;
}
.chordGridChordTooltipLimitHighlight {
  color: #f3bb33;
  text-decoration: underline;
  cursor: pointer;
}
.chordGridChordTooltipBlock {
  padding: 1px 6px;
  border-radius: 2px;
  background: rgba($grey10, 0.2);
  color: $darkThemeText;
}

.chordGridNoChords {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  svg {
    width: 32px;
  }
  span {
    font-family: Quicksand;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.6px;
    text-align: center;
  }

  span,
  svg path {
    color: $secondaryGray36;
  }
}

.chordLoading {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.chordLoadingLoader {
  width: 136px;
  height: 8px;
  border-radius: 10px;
  position: relative;
  background: rgba($lightTheme, 0.04);

  & > div {
    height: 8px;
    background: linear-gradient(103.32deg, #3d4ff7 6.46%, #c145ed 98.38%);
    border-radius: 1000px;
    animation: loading 1s infinite ease-in-out;
    position: absolute;
  }
}
.chordLoadingText {
  font-weight: 600;
  font-size: 12px;
  color: $grey10;
}

// ChordEditorContent

.chordEditorContent {
  padding-top: 24px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  gap: 32px;

  @include mobile {
    padding-top: 0;
  }
}
.chordEditorContentInnerContent {
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: row;
  gap: 24px;

  @include mobile {
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

    padding-top: 24px;
  }
}

// SearchChord

.searchChord {
  position: relative;

  flex: 1;
  height: 100%;

  padding: 16px 44px 16px 20px;

  border-radius: 10px;
  background: $dark20;
  border: 1px solid $dark30;

  @include mobile {
    max-height: 82px;
  }
}
.searchChordIcon {
  width: 48px;
  height: 48px;

  position: absolute;
  top: 4px;
  right: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
  }
  path {
    color: #7c7d7e;
    @include transition(all);
  }
}
.searchChordIconClear {
  cursor: pointer;

  &:hover {
    svg path {
      color: $darkThemeText;
    }
  }
}
.searchChordTextarea {
  width: 100%;
  height: 100%;
  resize: none;
  background: transparent;
  border: none !important;
  outline: none !important;

  font-family: Quicksand;
  font-weight: 600;
  font-size: 18px;
  line-height: 25.2px;
  letter-spacing: 0%;

  color: $darkThemeText;

  @include scroll-bar();

  &::placeholder {
    color: $secondaryGray36;
  }

  @include mobile {
    font-size: 16px;
  }
}

// ChordPalette

.chordPaletteContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @include mobile {
    gap: 8px;
  }
}
.chordPalette {
  display: flex;
  flex-direction: row;
  gap: 1px;

  border-radius: 6px;
  background: $dark30;
  border: 1px solid $dark30;
  overflow: hidden;
}
.chordPaletteOption {
  width: 96px;
  height: 32px;

  background: $darkTheme;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  cursor: pointer;
  @include transition(all);

  span {
    font-family: Quicksand;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.5px;
    letter-spacing: 0%;
    text-align: center;

    color: $secondaryGray38;
    @include transition(all);
  }

  &:not(.chordPaletteOptionActive):hover {
    background: $dark20;
  }
}
.chordPaletteOptionDisabled {
  span {
    opacity: 0.4;
  }
}
.chordPaletteOptionActive {
  @include gradientBgTransition(#3d4ff7, #457eed);
}
.chordPaletteLimitIcon {
  position: relative !important;
  top: unset !important;
  right: unset !important;

  width: 12px !important;
  height: 12px !important;
}

// ChordKeyMenu

.chordKeyMenuTrigger {
  width: 152px;
  height: 100%;
  max-height: 178px;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-radius: 6px;
  border: 1px solid $dark30;
}
.chordKeyMenuTriggerRow {
  flex: 1;

  display: flex;
  flex-direction: row;

  &:nth-last-child(1) .chordKeyMenuTriggerButton {
    border-bottom: none;
  }
}
.chordKeyMenuTriggerButton {
  flex: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Quicksand;
  font-weight: 700;
  font-size: 14px;
  line-height: 17.5px;
  letter-spacing: 0%;
  text-align: center;

  color: $secondaryGray38;
  border-bottom: 1px solid $dark30;
  border-right: 1px solid $dark30;
  cursor: pointer;
  @include transition(all);

  &:nth-last-child(1) {
    flex: 1;
    border-right: none;
  }

  svg {
    width: 18px;
    opacity: 0.6;

    path {
      color: $secondaryGray38;
    }
  }

  &:not(.chordKeyMenuTriggerButtonActive):hover {
    background: $dark23;
  }
}
.chordKeyMenuTriggerButtonActive {
  background: $secondaryBlue20;
}

.chordKeyMenuDropdown {
  width: 320px !important;
  padding: 0 !important;
  opacity: 1 !important;

  background: $darkTheme !important;
  border: 1px solid $dark30 !important;

  & > div:nth-last-child(1) {
    border: 1px solid transparent;

    border-top: 1px solid $dark30;
    border-right: 1px solid $dark30;

    transform: rotate(45deg);
  }
}
.chordKeyMenuDropdownFooterMode {
  & > div:nth-last-child(1) {
    border-top: 1px solid transparent;
    border-bottom: 1px solid $dark30;
  }
}
.chordKeyMenuDropdownBody {
  width: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 0 0 16px 0;
  }
}
.chordKeyMenuDropdownActions {
  width: 100%;
  padding: 16px;
  border-top: 1px solid $dark30;

  @include mobile {
    padding: 16px 0 0;
  }
}

.chordKeyMenuDropdownHeader {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.chordKeyMenuDropdownTitle {
  font-family: Quicksand;
  font-weight: 700;
  font-size: 14px;

  color: $secondaryGray38;

  @include mobile {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;

    color: $darkThemeDark2Text;
  }
}
.chordKeyMenuDropdownTypeBtn {
  width: 64px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Quicksand;
  font-weight: 700;
  font-size: 14px;

  color: $secondaryGray38;

  border-radius: 6px;
  border: 1px solid $dark30;
  cursor: pointer;
  user-select: none;

  transition: color 250ms ease-out, border-color 250ms ease-out;
}
.chordKeyMenuDropdownTypeBtnActive {
  color: #457eed;
  border-color: #457eed;
}

.chordKeyMenuDropdownContent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1px;

  margin: 8px 0 12px;

  border-radius: 6px;
  border: 1px solid $dark30;
  background: $dark30;
  overflow: hidden;
}
.chordKeyMenuDropdownKey {
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: $dark20;
  cursor: pointer;
  @include transition(background);

  font-family: Quicksand;
  font-weight: 700;
  font-size: 16px;
  color: $darkThemeText;

  &:not(.chordKeyMenuDropdownActiveKey):hover {
    background: $dark23;
  }
}
.chordKeyMenuDropdownActiveKey {
  @include gradientBgTransition(#3d4ff7, #457eed);
  transition: opacity 0s, background 250ms ease-out;
  cursor: default;
}

.chordKeyMenuDropdownFooter {
  display: flex;
  flex-direction: row;
  gap: 1px;

  border-radius: 6px;
  border: 1px solid $dark30;
  background: $dark30;
  overflow: hidden;
}
.chordKeyMenuDropdownScale {
  flex: 1;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: $dark20;
  cursor: pointer;

  font-family: Quicksand;
  font-weight: 700;
  font-size: 14px;

  color: $secondaryGray38;

  &:not(.chordKeyMenuDropdownActiveScale):hover {
    background: $dark23;
  }
}
.chordKeyMenuDropdownActiveScale {
  @include gradientBgTransition(#3d4ff7, #457eed);
  transition: opacity 0s, background 250ms ease-out;
  cursor: default;
}

.chordKeyMenuDropdownBtn {
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  @include gradientBgTransition(#3d4ff7, #457eed);
  transition: opacity 0s, background 250ms ease-out;
  cursor: pointer;
  border-radius: 8px;

  font-family: Quicksand;
  font-weight: 700;
  font-size: 14px;
  color: $darkThemeText;
  user-select: none;
}
.chordKeyMenuDropdownBtnDisabled {
  background: $dark23;
  opacity: 0.5;
  cursor: default;
}

.chordKeyMenuTriggerFooter {
  width: 56px;
  height: 48px;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include transition(all);
  cursor: pointer;

  &:hover {
    background: $gray20;
  }
}
.chordKeyMenuTriggerFooterValue {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 18px;
  color: $darkThemeText;
}
.chordKeyMenuTriggerFooterName {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 12px;
  color: $secondaryGray36;
  text-transform: capitalize;
}
.chordKeyMenuTriggerFooterActive {
  background: $gray20;
}

// NotInScaleContainer

.notInScaleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  @include mobile {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
}
.notInScaleContent {
  width: fit-content;
  height: fit-content !important;
  min-height: 72px;
}
.notInScaleDescription {
  width: min-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;

  svg {
    width: 100%;

    @include mobile {
      width: 12px;

      transform: rotate(90deg);
    }
  }
  span {
    font-family: Quicksand;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
  }
  span,
  svg path {
    color: $secondaryGray36;
  }
}
.notInScaleBtn {
  height: 100%;
  padding: 0 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  border: 1px solid $dark30;
  background: $dark20;
  cursor: pointer;
  @include transition(all);

  font-family: Quicksand;
  font-weight: 700;
  font-size: 14px;
  color: $darkThemeText;

  &:hover {
    background: $dark23;
  }

  @include mobile {
    height: 48px;
  }
}

@keyframes loading {
  0% {
    width: 0;
    left: 0;
  }
  35% {
    width: 136px;
    left: 0;
  }
  70% {
    left: 136px;
    width: 0;
  }
  71% {
    width: 0;
  }
}

.chordContentScale {
  max-width: 100%;
  padding: 1px 6px;

  font-family: Quicksand;
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  text-align: center;
  white-space: nowrap;

  color: $secondaryGray38;
  background: rgba($darkTheme, 0.5);
  border-radius: 4px;
  overflow: hidden;
}
.chordContentName {
  font-family: Quicksand;
  font-weight: 700;
  font-size: 18px;
  line-height: 22.5px;
  text-align: center;

  color: $darkThemeText;
}
.chordContentDegree {
  font-family: Quicksand;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: $secondaryGray32;
}
.matchLevel {
  position: absolute;
  left: 8px;

  & > div,
  & > div > div {
    margin-top: auto;
    width: 4px;
  }

  &[data-level='medium'] {
    & > div {
      background: rgba($secondaryGreen, 0.5);

      & > div {
        background: $secondaryGreen;
      }
    }
  }

  &[data-level='low'] {
    & > div {
      background: rgba($secondaryBlue3, 0.5);

      & > div {
        background: $secondaryBlue3;
      }
    }
  }

  &[data-level='perfect'] {
    & > div {
      background: rgba($secondaryPurple, 0.5);

      & > div {
        background: $secondaryPurple;
      }
    }
  }

  &[data-selected='true'] {
    & > div {
      background: rgba(28, 28, 28, 0.31);

      & > div {
        background: $darkThemeText;
      }
    }
  }
}
