@import '~/styles/variables';

.bpmSelect {
  position: relative;
  width: 48px;
  z-index: 100;
  margin-right: 8px;

  &:not(.removeMobileChanges) {
    @include mobile {
      height: 92px;
      width: auto;
      flex-grow: 1;
      margin-right: 0;
    }

    .menu {
      @include mobile {
        padding-top: 0;
        background: none;
        transform: none;
        border: none;
        width: auto;
        position: static;
      }
    }

    span {
      @include mobile {
        margin-right: auto;
        margin-bottom: 12px;
        font-size: 18px;
        span {
          color: $darkThemeText;
        }
      }
    }
  }

  &.lite {
    width: fit-content;
    padding: 8.5px 16px;

    .trigger {
      flex-direction: row-reverse;

      span {
        font-size: 18px;
        font-weight: 300;
        line-height: 21px;
      }

      .inputCon {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        svg {
          width: 18px;
          height: 18px;

          path {
            stroke: $darkThemeDark2Text;
          }
        }
      }
    }

    .menu {
      top: auto;
      bottom: 3px;
      transform: translate(-50%, 100%);
    }
  }
}
.menu {
  background: $secondaryGray;
  width: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  border-radius: 4px;
  border: 1px solid $secondaryGray2;
  top: -90px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  span {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
    color: $darkThemeDark2Text;
    user-select: none;
  }
}

.slider {
  width: 100%;
}

.input {
  background: none;
  outline: none;
  border: none;
  width: 48px;
  text-align: center;
  box-shadow: none;
  color: $darkThemeText;
  font-size: 18px;
  padding-bottom: 2px;
  user-select: none;
  font-weight: 600;
  @include bigDesktop {
    font-size: 22px;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-moz-selection {
    background: transparent;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}
