@import '~/styles/variables';

.container {
  padding: 24px 24px 24px;
  @include nonMobile {
    background: $darkTheme;
  }
  @include mobile {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    p {
      margin: 0 0 8px;
      text-align: left;
    }
  }
  input {
    border: 1px solid #5f6062;
    border-radius: 4px;
    height: 45px;
    width: 100%;
    color: $darkThemeText;
    background: none;
    padding-left: 12px;
    font-weight: 600;
    font-size: 14px;
    outline: none;
    &::placeholder {
      color: rgba($darkThemeText, 0.5);
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $darkTheme inset !important;
    }
  }
}

.wrapper {
  border-radius: 4px;
  @include nonMobile {
    border: 1px solid $secondaryGray;
    overflow: hidden;
  }
  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.header {
  border-radius: 4px 4px 0 0;
  display: flex;
  height: 58px;
  width: 100%;
  background-color: #17181b;
  align-items: center;
  padding-left: 24px;
  svg {
    margin-right: 12px;
  }
  h2 {
    font-weight: 600;
    font-size: 18px;
    margin-left: 32px;
  }
  svg path {
    stroke: $darkThemeText;
  }
}

.suggestions {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 8px;
  span {
    @include bgTransition($gray30, $secondaryGray);
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 6px 8px;
    user-select: none;
    &[data-active='true'] {
      @include bgTransition($secondaryBlue6, $secondaryBlue10);
    }
  }
}

.continueButton {
  width: 100%;
  outline: none;
  border: none;
  margin-top: 30px;
  box-shadow: none;
  height: 45px;
  @include gradientBgTransition(#3d4ff7, #457eed);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: $darkThemeText;
  margin-bottom: 0;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
  }
}

.closeIconName {
  top: 17px;
  left: 22px;

  &.generatorStep {
    margin-top: 7px;

    svg path {
      stroke: $darkThemeDark2Text;
    }
  }
}

.buildGenerateButtons {
  height: 118px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  margin-top: 16px;
  .buildButton,
  .generateButton {
    flex-direction: column;
    gap: 4px;
    border-radius: 4px;
    span {
      padding: 0;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .buildButton {
    @include bgTransition($secondaryGray14, $secondaryGray);
    &.loading {
      @include shimmer(2.2s);
      background-image: linear-gradient(to right, $secondaryGray14 8%, $secondaryGray 45%, $secondaryGray14 90%);
    }
  }
  .generateButton {
    @include bgTransition($secondaryBlue8, $secondaryBlue10);
  }
}

.generatorModalContainer {
  padding: 20px 24px 32px;
  height: 306px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.modalCon {
  position: unset;
  width: 100%;
  padding-top: 13px;

  &.chords {
    background-size: 313px auto;
    background-position: left top;
  }

  .returnIcon {
    position: absolute;
    top: 22px;
    left: 24px;

    svg {
      width: 14px;
      height: 14px;
    }

    &:hover {
      svg {
        path {
          stroke: $darkThemeText;
        }
      }
    }

    @include mobile {
      top: 4px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .modalHead {
    margin-bottom: 22px;
  }

  @include mobile {
    background: transparent !important;
    border: none;
    padding-top: 0;
    margin-top: -6px;

    .modalHead {
      margin-bottom: 12px;
    }
  }
}
