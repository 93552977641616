@import '~/styles/variables';

.footer {
  background: #1d1e21;
  box-shadow: 0px 4px 20px rgba(77, 75, 75, 0.1);
  margin-top: auto;
  &:not(.generator) {
    padding: 36px 0;
  }
  &.generatorOnboarding {
    z-index: 1000;
  }
  &.light {
    background: $lightTheme;
    color: $darkThemeDark2Text;
    a {
      &:hover {
        color: $lightThemeText2;
      }
      &.activeLink {
        color: $lightThemeText2;
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyright {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #8b8c8e;
  margin: 0;
}

.menu {
  display: flex;
  gap: 40px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #8b8c8e;
    display: block;
    transition: 0.3s;
    &:hover {
      color: #fff;
    }
  }
  @include tablet {
    gap: 32px;
  }
}

@include tablet {
  .container {
    flex-direction: column-reverse;
    gap: 32px;
  }
}

@include mobile {
  .footer {
    padding: 16px 0;
  }
  .container {
    gap: 24px;
  }
  .menu {
    flex-wrap: wrap;
    gap: 16px 32px;
    justify-content: center;
  }
  .copyright {
    font-size: 14px;
  }
}
