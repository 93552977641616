@import '~/styles/variables';

.tick {
  flex-grow: 1;
  background: rgba(92, 128, 255, 0.3);
  border-radius: 4px;
  height: 2px;
  position: relative;
  .tickBody {
    background-color: $secondaryBlue6;
    height: 100%;
    position: absolute;
    border-radius: 4px;
  }
}

.container {
  width: 100%;
  display: flex;
  gap: 3px;
  padding-left: 1px;
  padding-right: 1px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
