@import '~/styles/variables';

.container {
  width: 100%;
  height: 8px;
  background: $darkTheme;
  position: relative;
  border-radius: 4px;
  cursor: pointer;

  .thumb {
    position: absolute;
    width: 30%;
    height: 6px;
    top: 1px;
    border-radius: 4px;
    background: $secondaryGray;
    &[data-scaling=true] {
      transition: width 50ms linear, left 50ms linear;
    }
  }
}
