@import '~/styles/variables';

.overflow {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  width: 100%;
  padding-top: 45px;

  display: flex;
  flex-direction: column;
  gap: 1px;

  @include nonMobile {
    position: relative;
    overflow: hidden !important;
  }
  @include mobile {
    padding-left: 0;
    height: 100%;
  }
  &[data-not-scrollable='true'] {
    position: relative;
  }
  &,
  & * {
    touch-action: auto;
  }
  &[data-not-scrollable='true'] {
    overflow: hidden !important;
  }
  &[data-pinching='true'] {
    overflow: hidden !important;
  }
  &[data-onboarding='true'] {
    overflow: visible !important;
  }
  @include scroll-bar();
  &::-webkit-scrollbar {
    height: 7px;
  }
}

.chromeInitialScreen {
  border: 1px dashed $darkThemeDarkText;
  background: $darkTheme;
  border-radius: 12px;
  height: ($tileHeightSmall + 3px) * 2;
  margin: 34px 7px 0 1px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;

  @include mobile {
    height: $tileHeightMobile + 74;
    gap: 12px;
    border: none;
    margin: 33px 8px 0 4px;
  }

  @include bigDesktop {
    height: 2 * $tileHeight + 4px;
  }

  .suggestions {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    span {
      height: 30px;
      @include bgTransition($gray30, $secondaryGray);
      color: $darkThemeText;
      cursor: pointer;
      border-radius: 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      padding: 6px 8px;
      user-select: none;
      &[data-active='true'] {
        @include gradientBgTransition($secondaryBlue12, $secondaryBlue13);
      }
    }

    @include mobile {
      width: 100%;
      padding: 0 12px;
      flex-wrap: nowrap;
      overflow: scroll hidden;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  .buildGenerateButtons {
    display: flex;
    flex-direction: row;
    gap: 12px;

    .buildButton,
    .generateButton {
      width: 132px;
      height: 132px;
      flex-direction: column;
      gap: 4px;
      border-radius: 4px;
      span {
        padding: 0;
      }
      svg {
        width: 40px;
        height: 40px;
      }
      @include mobile {
        width: 120px;
        height: 120px;
      }
    }
    .buildButton {
      @include bgTransition($secondaryGray14, $secondaryGray);
      &.loading {
        @include shimmer(2.2s);
        background-image: linear-gradient(to right, $secondaryGray14 8%, $secondaryGray 45%, $secondaryGray14 90%);
      }
    }
    .generateButton {
      @include bgTransition($secondaryBlue8, $secondaryBlue10);
    }
  }
}

.chrome {
  margin: 0;
  padding-left: 8px;

  touch-action: pan-x;
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  @include mobile {
    padding-left: 48px;
  }
}

.grid {
  @mixin calculateHeight($height, $numOfChromes: 3) {
    height: $numOfChromes * ($height + 7px) + 44px;
  }

  position: absolute;
  top: 0px;
  width: 100%;
  @include calculateHeight($tileHeightSmall);
  z-index: 0;

  @include mobile {
    @include calculateHeight($tileHeightMobile);
  }
  @include bigDesktop {
    @include calculateHeight($tileHeight);
  }
}

.gridContainer {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 81%;
  @include mobile {
    height: 0;
  }
}

.break {
  flex-basis: 100%;
  height: 3px;
}

.playhead {
  position: absolute;
  border: 1px solid $lightTheme;
  top: 8px;
  width: 2px;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  background: $lightTheme;
}

.crown {
  position: absolute;
  top: -1px;
  left: -6px;
  color: $lightTheme;
}

.playheadZone {
  position: absolute;
  left: 0;
  top: 0;
  height: 45px;
  cursor: pointer;
  right: 0;

  &.playheadDragging {
    top: 0;
    bottom: -100vh;
    height: auto;
    z-index: 2;
  }
}

.row {
  display: flex;
  flex-direction: row;
  transition: all 0s ease !important;

  & > * {
    transition: all 0s ease !important;
  }
}

.partsMenuTrigger {
  width: 50vw;
  height: 40px;

  position: absolute;
  left: 0;
  top: -45px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  padding: 0 24px 0 16px;

  span {
    flex: 1;

    font-family: Quicksand;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.5px;
    text-align: left;

    color: $darkThemeText;

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    width: 18px;

    transform: rotate(180deg);
  }
}

.hoveredDrum {
  @include nonMobile {
    border-color: $secondaryGreen2 !important;
  }
}
.hoveredMelody {
  @include nonMobile {
    border-color: red !important;
  }
}
.loadingDrums,
.loadingMelody {
  @include shimmer(2.2s);
  background-image: linear-gradient(to right, $secondaryGray 8%, $secondaryGray3 45%, $secondaryGray 90%);
}
