@import '~/styles/variables';

.sliderWithText {
  width: 240px;
  max-width: 240px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  &.hidden {
    display: none;
  }
  &.hideControls {
    .muteButton,
    .text {
      visibility: hidden;
    }
  }
  &.hideThumb {
    .slider > div {
      background: #d4ddff;
    }
    .slider > div > div:nth-last-child(1) {
      display: none;
    }
    .slider > div > div:nth-last-child(2) {
      display: none;
    }
    .slider > div > div:nth-last-child(3) {
      border-radius: 10px 0 0 10px;
    }

    .slider {
      margin-right: 0;

      & > div {
        border-radius: 50px;
        overflow: hidden;

        & > div:nth-last-child(2) {
          border-radius: 0 50px 50px 0;
        }
      }
    }
  }
  &[data-small='true'] {
    width: 180px;
  }
  span {
    color: $darkThemeDark2Text;
    @include transition(color);
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    user-select: none;
  }
  .text {
    width: 25px;
  }

  .slider {
    margin-left: 8px;
    margin-right: 8px;
    flex-grow: 1;
  }

  &:hover:not([data-disabled='true']) {
    span {
      color: $darkThemeText;
    }
  }
}

.muteButton {
  width: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    height: 16px;
  }
}
