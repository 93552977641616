@import './variables.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  outline: none;
  padding-top: 72px;

  @include bigDesktop {
    padding-top: 90px;
  }

  @include mobile {
    height: 100%;
    padding-top: 64px;
  }
  * {
    user-select: none;
  }
}

.center {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 48px;
  position: relative;

  @include mobile {
    margin-top: 0;
  }
}

.addDrums {
  width: 250px;
  height: 36px;

  border-radius: 100px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  @include transition(all);
  background: #252629;
  margin-left: 16px;
  margin-top: 4px;
  cursor: pointer;

  svg {
    width: 16px;

    path {
      stroke: #8d8d8e;
    }
  }

  span {
    font-family: Quicksand;
    font-size: 13px;
    font-weight: 700;
    line-height: 16.86px;
    text-align: left;
    color: #8d8d8e;
  }

  &:hover {
    background: #2b2e33;
  }

  @include mobile {
    width: calc(50vw - 10px);
    margin-left: 5px;
  }
}
