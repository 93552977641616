@import '~/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 29px 4px 4px;

  .editButton {
    height: 40px;
    max-width: 100%;
    @include bgTransition(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.15));
    border-radius: 25px;
    padding: 0 26px;
    align-items: center;

    span {
      margin: 0;
      font-weight: 600;
      font-size: 16.601px;
      line-height: 21px;
      flex-shrink: 1;
    }

    svg {
      flex-shrink: 0;
    }

    @include mobile {
      height: 32px;
      padding: 0 16px;
      & > span {
        font-size: 14px;
        line-height: 18px;
        margin-top: 0;
      }
      svg {
        width: 18px;
      }
    }
  }

  .editDeleteContainer {
    position: absolute;
    left: 0;
    top: 25px;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    padding-right: 18px;
    padding-left: 18px;
    background: rgba($secondaryGray14, 0.3);
    @include nonTablet {
      &:not(:hover) {
        opacity: 0;
      }
    }
    @include mobile {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .menuButton {
    svg {
      height: 22px;
      margin-right: -2px;

      path {
        fill: $darkThemeDark2Text;
      }
    }

    &:hover {
      svg path {
        fill: $darkThemeText;
      }
    }

    position: relative;

    &:after {
      @include pseudo-full();
      left: calc(100% + 6px);
      width: 1px;
      top: 0;
      bottom: 0;
      background: #43444a;
    }
  }
}

.headerMenu {
  position: absolute;
  height: 25px;
  left: 0;
  top: 0;
  width: 100%;
  background: #303236;
  border-radius: 7px 7px 0 0;
  padding-left: 8px;
  display: flex;
  justify-content: space-between;

  .headerLeft {
    height: 15px;
    width: calc(100% - 70px);
    margin-right: 6px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;

    margin-top: 5px;

    span {
      cursor: pointer;
      max-width: calc(100% - 36px);
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $darkThemeText;
      margin-top: 0;

      display: flex;
      align-items: center;
      gap: 3px;
    }

    .loader {
      animation: linear rotate 2s infinite;
      margin-left: auto;
      margin-right: auto;
      height: 15px;
      width: 15px;
    }

    svg {
      width: 15px;
      height: 15px;

      path {
        @include transition(all);
      }

      &:hover {
        cursor: pointer;

        path {
          stroke: $darkThemeText;
        }
      }

      &:nth-child(1) {
        rotate: -90deg;
      }

      &:nth-child(3) {
        rotate: 90deg;
      }
    }

    @keyframes rotate {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .headerRight {
    display: flex;
    gap: 14px;
  }

  .dropdownMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    & > div:last-child {
      left: 24px;
      top: -40px;
      @include mobile {
        top: -70px;
      }
    }
  }

  .dragButton {
    cursor: move;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px 5px 0;

    * {
      cursor: move;
    }

    .dragIcon svg {
      width: 20px;
      height: 15px;
      color: $darkThemeDark2Text;
      @include transition(color);
    }

    &:hover {
      .dragIcon svg {
        color: $darkThemeText;
      }
    }
  }
}
