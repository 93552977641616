@import '~/styles/variables';

.container {
  max-width: $maxDesktopWidth;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  @include tablet {
    padding: 0 16px;
  }
}
