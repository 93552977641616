@import '~/styles/variables';

.container {
  height: 124px;
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(4, minmax(60px, 200px));
  grid-gap: 8px;
  grid-template-rows: 58px 58px;
  margin-right: 12px;
}

.emptyTile,
.addNewTile,
.tileContainer {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.addNewTile,
.tileContainer {
  outline: none;
  box-shadow: none;
  border: none;
}

.tileContainer {
  display: flex;
  flex-direction: row;

  background-color: $secondaryGray2;
  border-radius: 6px;
  overflow: hidden;
  @include transition(all);

  &:hover {
    background-color: $secondaryGray;
    cursor: pointer;
  }

  &[data-active='true'] {
    @include gradientBgTransition(#457eed, #3d4ff6);

    .btn {
      background-color: $secondaryBlue13;
      &:hover {
        background-color: #3d4ff6;
      }
    }
  }

  &.disabled {
    opacity: 0.3;

    &:hover {
      background-color: $secondaryGray2 !important;
      cursor: initial;
    }

    .btn:hover {
      background-color: $secondaryGray6;
    }
  }
}

.tile {
  font-size: 14px;
  font-weight: 500;
  flex-grow: 1;
  color: $darkThemeText;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.btn {
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 250ms ease-out;
  background-color: $secondaryGray6;
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: $darkThemeText;
    }
  }

  &:hover {
    background-color: $secondaryGray;
  }
}

.emptyTile {
  background-color: $secondaryGray3;
}

.addNewTile {
  background-color: $secondaryGray2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: $darkThemeText;
  &[data-loading='true'] {
    @include shimmer(3s);
    background-image: linear-gradient(to right, $secondaryGray2 8%, $secondaryGray3 45%, $secondaryGray2 90%);
  }
  svg {
    width: 26px;
    height: 26px;
    margin-right: 8px;
    path {
      stroke: $darkThemeText;
    }
  }
  &:not(.disabled):hover {
    background-color: $secondaryGray;
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.3;
  }
}
