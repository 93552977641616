@import '~/styles/variables.scss';

.settingsName {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  color: $darkThemeDark2Text;

  margin-bottom: 8px;
}

.nameSettings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 6px;

  input {
    flex: 1;
    padding: 0 20px;

    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    color: $darkThemeText;

    background: $secondaryGray3;

    border: none !important;
    outline: none !important;

    border-radius: 8px;
  }

  .save {
    width: 100px;
    min-width: 100px;
    height: 52px;
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    color: $darkThemeText;

    background: linear-gradient(103.32deg, $secondaryBlue12 6.46%, $secondaryPurple3 98.38%);

    @include transition(all);

    &.disabled {
      opacity: 0.6;
    }
  }
}

.bpmSettings {
  padding: 14px 20px;

  border-radius: 8px;
  border: 1px solid $secondaryGray3;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .btn {
    width: 46px;
    height: 46px;

    background: $secondaryGray3;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0.6;
    @include transition(all);

    svg {
      width: 20px;
    }

    &.active {
      opacity: 1;
    }
  }

  .value {
    height: 40px;
    position: relative;

    .input {
      background: none;
      outline: none;
      border: none;
      width: 49px;
      text-align: center;
      box-shadow: none;
      padding: 0;
      max-height: 33px;
      height: 33px;
      font-size: 26px;
      font-weight: 600;
      line-height: 33px;
      letter-spacing: 0;

      color: $darkThemeText;

      user-select: none;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::-moz-selection {
        background: transparent;
      }

      &::selection {
        background: transparent;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    .text {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      text-align: center;
      white-space: nowrap;
      position: absolute;
      bottom: -10px;
      color: $darkThemeDark2Text;
    }
  }
}
