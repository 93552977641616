@import '~/styles/variables';

.slider {
  user-select: none;
  width: 100%;

  &.small {
    &.vertical {
      width: 8px;
      padding: 4px;
      height: 100%;
      & > div {
        padding: 4px;
      }
    }

    &.horizontal {
      height: 12px;
    }
  }

  &.medium {
    height: 12px;
  }

  &:global(.blue) > .track:first-child {
    background: $secondaryBlue5;
  }

  &:global(.blue-marketing) > .track {
    &:first-child {
      background: #4564e4;
    }

    &:nth-child(2) {
      background: #d4ddff;
    }
  }

  &:global(.green) > .track:first-child {
    background: $secondaryGreen;
  }

  &:global(.white) > .track:first-child {
    background: $darkThemeText;
  }

  &.disabled {
    & > .track:first-child {
      background: $secondaryGray3;
    }
  }
}

.thumb {
  cursor: pointer;
  outline: none;
  transform: translate(0, -4px);
  z-index: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: white;
  box-shadow: 0px 1px 4px 0px #00000040;
  border-radius: 50%;
  &:after {
    @include pseudo-full();
    position: relative;
  }
  &.vertical {
    transform: translate(-6px, 0);
  }

  &.horizontal {
    top: 0;
  }

  &.medium {
    top: 1px;
  }

  &.disabled {
    background: $secondaryGray2;
  }

  @include mobile {
    &:after {
      margin: -5px;
      width: 30px;
      height: 30px;
    }
  }
}

.track {
  background: $darkThemeDarkText;
  border-radius: 8px;
  cursor: pointer;
  padding: 6px;

  &.small {
    &.vertical {
      width: 8px;
    }

    &.horizontal {
      height: 8px;
    }
  }

  &.medium {
    height: 12px;
  }

  &.disabled {
    background-color: $secondaryGray3;
  }
}
