@import '~/styles/variables';

.loopsMenu {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 13px 7px;

  & > div {
    flex-grow: 1;
    justify-content: space-between;
  }

  .loopsMenuControl {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    transform: none;
    &[data-disabled='false'] {
      @include bgTransition(transparent, $secondaryGray);
    }

    &[data-disabled='true'] {
      background: transparent;
      svg path {
        stroke: $darkThemeDarkText;
      }
    }
  }

  .loopsMenuText {
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    color: $darkThemeDarkText;

    span {
      color: $darkThemeText;
    }
  }
}

.loopOnlyOption {
  position: relative;
  margin-bottom: 8px;

  &:before {
    content: '';
    position: absolute;
    left: 16px;
    width: calc(100% - 32px);
    top: calc(100% + 4px);
    height: 1px;
    background-color: $secondaryGray !important;
  }
}

.triggerWrapper {
  width: 24px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mobile {
    width: 36px;
    height: 46px;
    margin-left: -6px;
  }
}

.loopOnlyCheckbox {
  flex-shrink: 0;
  margin-top: 2px;
  margin-left: 6px;
}

.dropDown {
  z-index: 2;
  position: relative;
  height: 24px;
  display: flex;
  align-items: center;
  &:not(.short) > div > div:last-child {
    top: 48px;
  }
  &.short > div > div:last-child {
    top: 45px;
  }

  @include mobile {
    &:not(.short) > div > div:last-child {
      right: 30px;
      left: auto;
      top: 55px;
    }
    &.short > div > div:last-child {
      left: 30px;
    }
  }
}
