@import '~/styles/variables';

.generateButton {
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  border-radius: 48px;

  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  color: $lightTheme;

  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  position: relative;

  &.green {
    @include bgTransition($secondaryGreen, $secondaryGreen6);
    @include transition(all);

    &.inactive {
      background: $secondaryGreen;
    }

    .icon {
      background: $secondaryGreen6;
    }

    &:before {
      background: rgba($secondaryGreen, 0.2);
    }
  }

  &.blue {
    @include bgTransition($secondaryBlue8, $secondaryBlue9);
    @include transition(all);

    &.inactive {
      background: $secondaryBlue8;
    }

    .icon {
      background: $secondaryBlue9;
    }

    &:before {
      background: rgba($secondaryBlue8, 0.2);
    }
  }

  &.small {
    width: 178px;
    height: 48px;

    .icon {
      width: 48px;
      height: 48px;

      svg {
        width: 36px !important;
        height: 36px !important;
      }
    }

    @include mobile {
      width: 213px;
      height: 60px;

      span {
        line-height: 22.5px;
      }

      .icon {
        width: 60px;
        height: 60px;

        svg {
          width: 45px !important;
          height: 45px !important;
        }
      }
    }
  }

  &.big {
    width: 213px;
    height: 60px;

    span {
      line-height: 22.5px;
    }

    .icon {
      width: 60px;
      height: 60px;

      svg {
        width: 45px !important;
        height: 45px !important;
      }
    }
  }

  span {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    z-index: 1;
  }

  .icon {
    z-index: 1;
    border-radius: 50%;

    svg {
      position: relative;
      z-index: 1;

      path {
        stroke: none !important;
      }
    }

    [class*='allow-spin'] {
      transform-origin: center;
    }
  }

  &[data-loading='true'] {
    .icon [class*='allow-spin'] {
      animation: icon-spin infinite 1s linear;
    }
  }

  &.inactive {
    opacity: 0.5;
    &,
    & * {
      cursor: auto;
    }
  }

  &[data-animate='true']:not(.inactive):before {
    content: '';
    position: absolute;
    transform-origin: center;
    border-radius: 50px;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    animation: rippleMob 1.4s infinite;
  }
}

@keyframes rippleMob {
  0% {
    transform: scale(1);
    opacity: 1;
    z-index: 1;
  }
  100% {
    transform: scaleX(1.1) scaleY(1.4);
    opacity: 0;
    z-index: 1;
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
