@import '~/styles/variables';

$footerControlsBigHeight: 85px;
$footerControlsHeight: 65px;

$footerPianoRollMobileHeight: 95px;
$footerPianoRollHeight: 130px;
$footerPianoRollBigHeight: 160px;

div.footer {
  border: none;
  position: fixed;
  bottom: 0;
  z-index: 1;
  @include tablet {
    z-index: 4;
  }
  @include nonMobile {
    border-top: none !important;
  }

  .wrapper {
    background: $darkTheme;
    flex-direction: column;
    display: flex;
    width: 100vw;
    padding: 0 0 0;
    height: min-content;
    position: absolute;
    left: 0;
    bottom: 0;
    border-top: 1px solid $secondaryGray;
    @include mobile {
      border-top: none;
    }
  }

  .activeTabContainer {
    width: 100%;
    max-height: calc(100vh - 64px - 32px - 52px);
    background-color: $darkTheme;
    display: flex;
    flex-direction: column;

    &:not([data-visible='true']) {
      overflow: hidden;
    }
    & > div {
      min-height: 0 !important;
    }

    .activeTabInnerWrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        width: 100%;
        height: 100%;
      }
    }
  }

  .instrumentContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }

  .instrumentRoll {
    width: fit-content;
    max-width: 100%;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-gutter: stable both-edges;
    @include scroll-bar();
    @include mobile {
      z-index: 4;
      background: $darkTheme;
      &,
      & * {
        touch-action: pan-x;
      }
    }

    &.guitarOpen {
      width: 100%;
    }
  }

  .bottom {
    padding-left: 40px;
    padding-right: 40px;
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-columns: 300px auto 300px;
    height: $footerControlsHeight;
    margin-left: auto;
    margin-right: auto;
    @include bigDesktop {
      height: $footerControlsBigHeight;
    }

    @include tablet {
      padding-left: 24px;
      padding-right: 24px;
      grid-template-columns: 1fr;
    }
    @include mobile {
      padding-left: 0;
      padding-right: 0;
      grid-template-columns: 1fr;
      height: 64px;
    }
  }

  .controls {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-self: center;
    @include mobile {
      width: 100%;
      &[data-standalone='true'] {
        margin-top: -30px;
      }
    }
  }

  &.hidden {
    display: none;
  }
}

.menuContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  .generatorButton {
    &.active,
    &.active:hover {
      background-color: rgba(#4876ff, 0.08);

      span {
        color: #5f87ff;
      }

      svg path {
        fill: #5f87ff;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}

.bgTabMenu {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  overflow: hidden;
  position: relative;

  .menuContainer {
    gap: 12px;
  }

  @include mobile {
    margin-top: 16px;
    position: absolute;
    right: 0;
    bottom: 110px;
  }

  .wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  .customizeButton,
  .pianoButton,
  .lyricsButton,
  .drumsEditorButton,
  .drumsButton,
  .generatorButton {
    height: 100px;
    width: 100px;
    margin: 0;
    flex-shrink: 0;
    background-color: $darkTheme;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 4px;

    span {
      padding: 0;
      @include transition(color);
      color: $darkThemeText;
      font-weight: 500;
      font-size: 14px;
    }

    svg {
      width: 40px;
      height: 40px;
      margin-bottom: 4px;
    }

    &:not(:hover) {
      span {
        color: $darkThemeDark2Text;
      }

      svg path {
        fill: $darkThemeDark2Text;
      }
    }
  }

  .lyricsButton svg path {
    fill: none !important;
  }

  @include nonMobile {
    .generatorButton {
      &:hover {
        background-color: rgba(#4876ff, 0.08);

        span {
          color: #5f87ff;
        }

        svg * {
          color: #5f87ff;
        }

        svg path {
          fill: #5f87ff;
        }
      }
    }
    .lyricsButton {
      &:hover {
        background-color: #29271e;

        span {
          color: $secondaryYellow3;
        }

        svg {
          color: $secondaryYellow3;
        }
      }
    }
    .drumsEditorButton {
      &:hover {
        background-color: rgba($secondaryGreen, 0.08);

        span {
          color: $secondaryGreen3;
        }

        svg {
          color: $secondaryGreen3;
        }
      }
    }
    .pianoButton,
    .customizeButton,
    .drumsButton {
      &:hover {
        svg {
          color: $darkThemeText !important;
        }
      }
    }
  }

  .pianoButton,
  .customizeButton,
  .drumsButton {
    svg {
      color: $darkThemeDark2Text !important;

      path,
      rect {
        @include transition(all);
      }
    }
  }

  .drumsButton:not(.inline) {
    width: 100%;
    margin-bottom: 12px;
  }
}

.shadow {
  @include pseudo-full();
  background-color: $darkTheme;
  opacity: 0.6;
  z-index: 5;
}

.launchpadContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 20px;
  padding-bottom: 16px;
  & > div {
    position: relative;
  }

  p {
    max-width: 45vw;
    width: 220px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 16px;
    color: $darkThemeText;
    text-align: right;

    span {
      font-weight: 700;
    }
  }
}

.launchpadButtonMobile {
  width: 64px;
  height: 64px;
  background-color: #2b2e33;
  border-radius: 40px;
  position: relative;
  z-index: 1;

  svg {
    width: 32px;
    height: 32px;
  }
}

.ripple,
.ripple2,
.ripple3 {
  animation: ripple 3s infinite ease-in;
  background-color: $darkThemeText;
  border-radius: 50%;
  position: absolute;
  content: '';
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
}

.ripple2 {
  animation-delay: 0.6s;
}

.ripple3 {
  animation-delay: 1.2s;
}

.stickyButtonsContainer {
  position: absolute;
  right: 0;
  top: 0;
}

.stickyButton {
  svg {
    height: fit-content !important;
    z-index: 1;
    position: relative;
    color: $secondaryGray3 !important;
  }

  &.lyrics:hover {
    svg {
      color: $secondaryYellow !important;
    }
  }
  &.onboarding:hover {
    svg {
      color: $secondaryBlue3 !important;
    }
  }
}

.onboardingFollowup {
  width: 330px;
  padding: 16px;

  background: $darkTheme;
  border-radius: 16px;
  border: 1px solid $secondaryGray;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  transform: translateX(-8px);

  z-index: 0;

  .text {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

    color: $darkThemeText;
  }

  svg {
    min-width: 36px;
    width: 36px;
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.15);
  }
  50% {
    box-shadow: 0 0 0 25px rgba(255, 255, 255, 0);
  }
}
