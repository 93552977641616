@import '~/styles/variables';

.wrapper {
  cursor: pointer;
  height: 100%;
  user-select: none;
  width: 200px;
  flex-shrink: 1;
  & > div {
    height: 100%;
  }

  &:not(:last-child) {
    .container {
      @include nonMobile {
        &:not(.looped) {
          border-right-color: transparent;
        }
      }
    }
  }

  @include mobile {
    height: 48px;
    flex-shrink: 0;
    width: auto;
    &:not(.mobileMain) {
      &:not(.dragging) {
        margin: 0 16px 10px;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}

.container {
  cursor: pointer;
  position: relative;
  height: 100%;
  background: $secondaryGray14;

  & {
    border: 1px solid $secondaryGray;
    border-top: none;
  }

  &.mobileMain {
    margin: 0;
  }

  &.active:after {
    position: absolute;
    content: '';
    pointer-events: none;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 2px solid transparent;
    border-radius: 4px;
    @include transition(border-color);
  }

  &.active.highlight {
    &:after {
      border-color: $secondaryGreen2;
    }
  }

  &.preview {
    background: repeating-linear-gradient(
      45deg,
      $secondaryGray,
      $secondaryGray 10px,
      rgba(38, 39, 42) 10px,
      rgba(38, 39, 42) 20px
    ) !important;
  }

  border-top: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $darkThemeText;

  & > * {
    justify-self: center;
  }

  &.smallMode {
    .dragElement {
      grid-template-columns: 1fr;
    }
    padding-left: 7px;
    padding-right: 7px;
  }
  @include nonTablet {
    &:hover,
    &.menuOpened {
      background-color: $secondaryGray;
    }
    &:not(:active) {
      .loopsCounter:hover {
        color: $darkThemeText;
      }
    }
  }

  &.active {
    background-color: #212532;
    color: $secondaryBlue6;
    .loopsCounter {
      color: $darkThemeText;

      &:hover {
        color: $secondaryBlue6;
      }
    }

    .dragIcon svg path {
      fill: $secondaryBlue6;
    }

    &.looped {
      .loopsCounter {
        path {
          fill: $secondaryBlue6;
        }
      }
    }
  }

  @include mobile {
    width: 100%;
    background: $darkTheme;
    &.mobileMain {
      border: none;
      border-radius: 0;
      &:not(.mobileMainOpened) {
        border-radius: 0 0 24px 24px;
        transition: border-radius 0.01s 0.35s ease-out;
      }
      margin-top: -8px;
      margin-left: 0;
      margin-right: 0;
      .dragIcon {
        display: none;
      }
    }
    &:not(.mobileMain) {
      background: $secondaryGray14;
      border: 1px solid $secondaryGray;
      border-radius: 8px;
      &.active {
        background-color: #212532;
      }

      &:not(.active):not(.dragging) {
        border-color: transparent;
      }
    }
  }
  &.dragging {
    width: calc(100% + 3px);
    @include nonMobile {
      padding-right: 1px;
    }
  }
}

.wrapper:last-child .simpleContainer {
  border-bottom-right-radius: 8px;
}

.dragElement {
  width: 100%;
  outline: none !important;
  display: grid;
  align-items: center;
  grid-template-columns: 30px minmax(0, 1fr) 30px;
  height: 100%;
  .smallModeBg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .dragElementMobile {
    position: absolute;
    width: 50px;
    left: calc(50% - 25px);
    height: 40px;
    z-index: 1;
  }
}

.loopsCounter {
  position: relative;
  z-index: 2;
  width: 30px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $darkThemeDark2Text;
  @include transition(color);
  @include mobile {
    color: $darkThemeText;
  }

  svg {
    width: 20px;
    margin-left: 4px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 0;
  max-width: 100%;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.container.smallMode {
  .dragIcon {
    display: none;
  }
}

.container.smallMode:hover:not(.dragging),
.container.smallMode.menuOpened:not(.dragging) {
  .content {
    display: none;
  }
}

.dragIcon {
  padding: 0 5px 2px;
  border-radius: 2px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  width: max-content;
  justify-content: center;
  position: absolute;
  top: 100%;
  left: calc(50% - 11px);

  svg path {
    fill: $darkThemeDark2Text;
    @include transition(fill);
  }
}

.expandIcon {
  height: 20px;
  margin-right: 6px;

  svg {
    transform: rotate(180deg);
    width: 20px;
    @include transition(transform);
  }
  &[data-open='true'] {
    svg {
      transform: rotate(0);
    }
  }
}

.addButton {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 8px;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
    height: 48px;
    flex-shrink: 0;
    margin: 0;
    width: auto;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.mobileMain.spawnAnimation {
    animation: spawn 0.36s ease-out;
  }
}

@keyframes spawn {
  0% {
    border-radius: 0;
  }
  95% {
    border-radius: 0;
  }
  100% {
    border-radius: 0 0 24px 24px;
  }
}

.addButtonContainer {
  padding: 16px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: $darkTheme;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -36px;
    height: 36px;
    background: linear-gradient(180deg, rgba(29, 30, 33, 0) 0%, $darkTheme 100%);
  }
  &.mobileMain {
    position: static;
    border: none;
    border-radius: 0 0 24px 24px;
    margin-top: -8px;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    &:before {
      display: none;
    }
  }
  @include mobile {
    padding-bottom: 16px;
    background: transparent;
  }
}

.dropDownWrapper {
  .dropDownTrigger {
    border-radius: 8px;
    height: 24px;
    width: 24px;
    display: block;
    @include transition(background-color);
    svg path {
      @include transition(fill);
    }
  }
}

@include nonTablet {
  .container {
    &:not(:hover) {
      .dragIcon,
      .dropDownTrigger:not([data-opened='true']) {
        opacity: 0;
      }
    }

    .dropDownWrapper:hover .dropDownTrigger {
      background-color: $secondaryGray2;
    }
  }
  .container.active {
    .dropDownWrapper:hover .dropDownTrigger {
      background-color: #272e46;

      &:hover {
        svg path {
          fill: $secondaryBlue6;
        }
      }
    }
  }
}

.container.active {
  .dropDownTrigger[data-opened='true'] {
    background-color: #272e46;
    &.dropDownTrigger[data-opened='true'] {
      svg path {
        fill: $secondaryBlue6;
      }
    }
  }
}

.dropDownTrigger[data-opened='true'] {
  background-color: $secondaryGray2;
}

.smallModeMenu {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container:not(.menuOpened) .dropDownTrigger {
  @include nonTablet {
    opacity: 0;
  }
}

.container:hover:not(.dragging) .dropDownTrigger {
  opacity: 1;
}

.simpleContainer {
  display: grid;
  grid-template-columns: 25px auto 15px;
  align-items: center;
  @include mobile {
    grid-template-columns: 25px auto 30px;
  }
  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

.tooltip {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  p {
    margin: 0;
    color: $darkTheme;
  }

  span {
    color: $darkThemeDarkText;
  }
}

.addPartDropdownContainer {
  position: relative;

  width: 200px;
  flex-shrink: 1;
  height: 100%;

  display: flex;
  align-items: center;

  @include mobile {
    width: 100%;
  }
}
.addPartDropdownMenu {
  width: 100%;
  height: 100%;

  & div:last-child {
    min-width: fit-content;
    top: calc(48px + 8px);

    @include mobile {
      right: 30px;
      left: auto;
      top: 55px;
    }
  }
}
.addPartDropdownItem {
  min-width: fit-content;
  white-space: nowrap;
  padding-right: 16px;
}

.limitIcon {
  top: 6px !important;
  right: 6px !important;

  width: 16px !important;
  height: 16px !important;
}
