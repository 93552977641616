@import '~/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 40px;
  @include mobile {
    flex-direction: column;
    gap: 0;
    margin-bottom: 32px;
  }
}

.imageWrapper {
  @include mobile {
    max-width: 70vw;
    margin-top: -8px;
  }
}

.title {
  font-size: 22px;
  font-weight: 700;
  color: $darkThemeText;
  line-height: 26px;
  span {
    font-weight: 400;
  }
  @include mobile {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
  }
}