@import '~/styles/variables';

.container {
  background: linear-gradient(121.32deg, rgba(#5a7fff, 0.2) 32.99%, rgba(#7bb8ff, 0.2) 96.81%);
  border-radius: 12px;
  height: 8px;
  position: relative;
  &[data-animate='true'] {
    background: none;
  }
}

.thumb {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 8px;
  background: linear-gradient(121.32deg, #5a7fff 32.99%, #7bb8ff 96.81%);
  border-radius: 12px;
  max-width: 100%;
  max-height: 100%;
  @include transition(width);
  &[data-animate='true'] {
    left: auto;
    height: 4px;
    background: linear-gradient(103.32deg, #3d4ff7 6.46%, #c145ed 98.38%);
    border-radius: 1000px;
    animation: loading 1s infinite ease-in-out;
  }
}

@keyframes loading {
  0% {
    width: 0;
    left: 0;
  }
  35% {
    width: 100%;
    left: 0;
  }
  70% {
    left: 100%;
    width: 0;
  }
  71% {
    width: 0;
  }
}
