@import '~/styles/variables';

.container {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 154px 1fr;
  @include bigDesktop {
    grid-template-columns: 1fr 180px 1fr;
  }
  grid-gap: 24px;
  flex-direction: row;
  position: relative;
  @include mobile {
    width: 100%;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 12px;
  }
}
.mainControls {
  display: flex;
  align-items: center;
  gap: 12px;
}
.backButton {
  svg {
    width: 26px;
  }
}
.repeatButton {
  svg {
    width: 26px;
  }
  svg path {
    @include transition(fill);
  }

  &.active {
    @include bgTransition($darkThemeText, $darkThemeText);

    svg path {
      fill: $darkTheme;
    }
  }
}

.playButton {
  position: relative;
  svg {
    width: 30px;
    height: 30px;
  }
}

.controlsContainer {
  display: flex;
  align-items: center;
}

.rightControlsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  @include mobile {
    justify-content: flex-end;
  }
}

.metronomeButton {
  margin-right: 8px;
  @include bgTransition($secondaryGray3, $secondaryGray);
  @include mobile {
    margin-right: 0;
    justify-self: flex-end;
    background-color: $secondaryGray3;
  }

  svg path {
    @include transition(all);
  }

  &.active {
    background-color: $secondaryBlue5;

    svg path:not([data-fill-active='true']) {
      stroke: $darkThemeText;
    }

    svg path[data-fill-active='true'] {
      fill: $darkThemeText;
    }
  }
}

.instrumentRollButton {
  justify-self: flex-end;
  background-color: $secondaryGray3;
  @include transition(background-color);
  svg {
    width: 26px;
  }
  &.piano {
    svg path,
    svg rect,
    svg line,
    svg mask {
      @include transition(all);
    }
  }

  @include nonTablet {
    &.active {
      background-color: $secondaryBlue5;

      &.piano {
        svg path,
        svg > rect,
        svg > line {
          stroke: $darkThemeText;
        }

        svg mask {
          fill: $darkThemeText;
        }
      }
    }
  }
  @include tablet {
    &.active {
      background-color: $secondaryBlue5;
    }
  }
  &:not(.piano).active {
    svg {
      color: $darkThemeText !important;
    }
  }
  &.disabled {
    opacity: 0.14;
  }
}

.instrumentsSelectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  .instruments {
    display: flex;
    height: 50px;
    border-radius: 39px;
    background: $secondaryGray3;
    padding-left: 4px;
    padding-right: 4px;
    align-items: center;
    gap: 8px;
  }

  .instrumentsClose {
    position: absolute;
    right: 16px;
  }
}

.undoButton,
.redoButton {
  width: 36px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-disabled='true'] {
    cursor: auto;
    svg path {
      fill: $darkThemeDark2Text;
    }
  }
  svg {
    width: 20px;
  }
}

.redoButton {
  transform: scale(-1, 1);
}
