@import '~/styles/variables';

.container {
  position: absolute;

  width: 186px;

  background: $darkTheme;
  border-radius: 8px;
  border: 1px solid $dark25;
  box-shadow: 0px 8px 30px 0px #0000008a;

  display: flex;
  flex-direction: column;
  gap: 8px;

  z-index: 100;

  .option {
    width: 100%;
    height: 40px;

    padding: 0 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background: transparent;
    @include transition(all);

    cursor: pointer;

    &:hover:not(.disabled) {
      background: $dark15;
    }

    .name,
    .command {
      font-size: 14px;
      font-weight: 500;
      line-height: 17.5px;
    }

    .name {
      color: $darkThemeText;
    }

    .command {
      color: $secondaryGray38;
    }

    &.disabled {
      cursor: default;

      .name,
      .command {
        color: $grey10;
      }
    }
  }
}

.mobileContainer {
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translate(-50%, -100%);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;

  padding: 5px 16px;

  border-radius: 50px;
  background: #2b2d31;

  .option {
    position: relative;

    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      color: $darkThemeText;
    }

    &::before {
      content: '';
      position: absolute;
      left: -13px;
      top: 50%;
      transform: translateY(-50%);

      width: 1px;
      height: 10px;
      background: $grey30;
    }

    &:nth-child(1) {
      &::before {
        display: none;
      }

      svg path {
        stroke: $lightTheme;
      }
    }
  }
}
