@import '~/styles/variables';

.container {
  width: 100%;
  background: $secondaryGray27;
  border-top: 1px solid $secondaryGray;
  color: $darkThemeText;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: auto;
  @include transition(all);
  @include bigDesktop {
    min-height: 86px;
  }
  @include tablet {
    min-height: 95px;
  }
  @include mobile {
    min-height: auto;
  }
}

.openArrow {
  position: absolute;
  width: 122px;
  height: 20px;
  cursor: pointer;
  left: calc(50% - 61px);
  display: flex;
  justify-content: center;
  align-items: center;
  top: -19px;
  touch-action: none;

  .arrow {
    width: 18px;
    height: 18px;
    transform: scale(1, -1);
  }

  .bg {
    stroke: $secondaryGray;
    fill: $secondaryGray27;
    position: absolute;
    left: 0;
    top: 0;
  }

  &[data-open='false'] {
    .arrow {
      transform: scale(1);
    }
  }
}

.content {
  height: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  @include mobile {
    &[data-closed='true'] {
      min-height: auto;
    }
  }
}
