@import '~/styles/variables';

.container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $secondaryGray3;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.8);
  border-radius: 4px 0px 0px 4px;
  z-index: 2;
  padding-top: 90px;
  @include bigDesktop {
    padding-top: 110px;
  }
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include transition(width);
  width: 0;

  &[data-status='entering'] {
    width: 410px;
  }

  &[data-status='entered'] {
    width: 410px;
  }

  &[data-status='exiting'] {
    width: 0;
  }

  &[data-status='exit'] {
    width: 0;
  }
}

.content {
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  display: flex;
  min-width: 410px;
  padding: 0 20px;
  gap: 28px;

  &.assistantOpen {
    filter: blur(2px);

    &,
    & * {
      cursor: pointer !important;
    }
  }
}

.closeIcon {
  position: absolute;
  left: 16px;
  top: -2px;
  z-index: 1;
  & > svg {
    min-width: 24px;
    min-height: 24px;
  }
}

.header {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    font-weight: 600;
    line-height: 18px;

    color: $secondaryGray16;

    opacity: 0.4;

    @include transition(all);

    svg {
      width: 18px;
      height: 18px;
    }

    &.active {
      opacity: 1;
    }
  }

  @include nonMobile {
    justify-content: flex-end;
    gap: 28px;

    .btn {
      &.clear {
        padding: 4px 8px;
        border-radius: 4px;
        background: $secondaryGray28;
        cursor: pointer;
      }
    }
  }
}

.generateLyrics,
.suggestions {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  z-index: 100;

  // TODO: uncomment once lyrics AI generation will be needed again
  // &::before {
  //   content: url('~assets/icons/generate-lyrics-stars.svg');

  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);

  //   width: 287px;
  // }

  .btn {
    position: relative;

    width: 240px;
    height: 50px;

    border-radius: 4px;

    background: linear-gradient(0deg, $secondaryBrown, $secondaryBrown),
      linear-gradient(0deg, $secondaryBrown2, $secondaryBrown2),
      linear-gradient(84.85deg, $secondaryYellow6 -0.01%, $secondaryYellow7 99.16%);

    background-size: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;

    font-size: 16px;
    font-weight: 600;
    line-height: 20px;

    color: $secondaryYellow;

    cursor: pointer;

    svg {
      width: 26px;
    }

    .stars {
      width: auto;

      position: absolute;
      left: 5px;
      top: 5px;
      right: 5px;
      bottom: 5px;
    }
  }
}

.suggestions {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;

  transform: none;

  background: $darkTheme;

  padding: 10px 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  overflow-x: auto;
  overflow-y: hidden;

  z-index: 2;

  &::before {
    display: none;
  }

  .btn {
    width: auto;
    min-height: 32px;
    height: 32px;

    padding: 0 8px;

    font-size: 12px;
    line-height: 15px;

    gap: 6px;

    cursor: pointer;

    svg {
      width: 18px;
    }
  }

  .suggestion {
    min-height: 32px;
    height: 32px;

    padding: 0 12px;

    background: $secondaryGray;

    border-radius: 4px;

    font-size: 12px;
    font-weight: 600;
    line-height: 15px;

    white-space: nowrap;

    color: $darkThemeText;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  &.noWord {
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap !important;
    gap: 8px;

    .noWordContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      padding: 35px 0;

      div {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;

        color: $darkThemeText;

        span {
          font-weight: 700;
        }
      }

      svg {
        width: 18px;
      }
    }
  }

  @include nonMobile {
    position: relative;
    margin: 0 -20px;

    padding: 16px 20px;

    overflow: hidden;

    flex-wrap: wrap;
    flex-shrink: 0;
  }
}

.assistant {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.skeleton {
  height: 32px;
  width: 60px;

  border-radius: 4px;

  @include shimmer(2.2s);
  background-image: linear-gradient(to right, $secondaryGray3 8%, $secondaryGray 45%, $secondaryGray3 90%);
}

.loader {
  width: 26px;
  height: 26px;

  &::before {
    border-color: $secondaryYellow;
  }
}
