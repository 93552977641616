@import '~/styles/variables';

.portalButtonContainer {
  position: relative;
  height: 100%;
  width: 160px;
  padding: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: 0 12px 12px 0;

  .portalButtonContainerInner {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;

    display: flex;
    flex-direction: row;

    .portalButton {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      border-radius: 0 0 0 48px;
      cursor: pointer;

      padding: 4px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .instrumentImage {
      flex: 1;

      position: relative;
      min-width: 50%;
      height: 100%;

      .portalButton {
        z-index: 1;
        left: 0;
        right: unset;

        border-radius: 0 0 48px 0;
        background: #000000b2;

        padding: 6px;
        justify-content: flex-start;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .btn {
      flex: 1;

      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @include transition(all);

      &:hover {
        background: transparent !important;
      }
    }

    .portalButtonContainerImage {
      width: 40px;
      height: 40px;
    }
  }

  &.chords,
  &.melody {
    background: $gray20;

    .portalButtonContainerInner {
      background: #15345a;

      .btn {
        background: $secondaryBlue13_16;
      }
    }

    svg path {
      transform-origin: center center;
      fill: $secondaryBlue8;
      @include transition(all);
    }

    .portalButton {
      background: $secondaryBlue13_16;
    }
  }

  &.drums {
    background: $gray20;

    .portalButtonContainerInner {
      background: $secondaryGreen9_16;

      .btn {
        background: $secondaryGreen9_16;
      }
    }

    svg path {
      transform-origin: center center;
      fill: $secondaryGreen;
      @include transition(all);
    }

    .portalButton {
      background: $secondaryGreen9_16;
    }
  }

  &.loading {
    .portalButtonContainerImage > g > g > path {
      animation: icon-spin infinite 1s linear;
    }
  }

  @include mobile {
    width: calc(50vw - 48px);
    height: $tileHeightMobile;
    margin-right: 6px;
    border-radius: 8px;
    transition: all 0.25s 0.25s ease-in-out !important;
    border-radius: 0 6px 0 0;

    .portalButtonContainerImage {
      width: 56px;
      height: 56px;
    }

    &.configHidden {
      transition: all 0.25s ease-in-out !important;
      width: calc(50vw - 6px);
    }
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-1turn);
  }
}

.regenerateMobileContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1px;

  margin-top: 32px;

  .redoButton,
  .undoButton {
    padding: 11px;

    svg {
      width: 18px;
      height: 18px;

      path {
        @include transition(all);
      }
    }

    &[data-disabled='true'] {
      cursor: auto;
      svg path {
        fill: $darkThemeText_20;
      }
    }
  }

  .redoButton {
    transform: scale(-1, 1);
  }

  .generateButton {
    svg path {
      transform-origin: center;
      fill: $lightTheme;
    }

    &.loading {
      svg > g > g > path {
        animation: icon-spin infinite 1s linear;
      }
    }
  }
}

.styleDropdown {
  height: 100%;
  @include transition(all);
}
.styleDropdownBody {
  border-top-left-radius: 0;
}
.styleDropdownTriggerOpen {
  filter: brightness(50%);
}
.styleDropdownTrigger {
  width: 100%;
  height: 100%;
  background: transparent !important;
}
