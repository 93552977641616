@import '~/styles/variables';

.settingsButton {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  gap: 4px;
  p {
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $darkThemeDark2Text;
    margin: 0;
    user-select: none;
    span {
      color: $darkThemeText;
    }
  }
}

.metronomeBlock {
  padding-left: 16px;
  margin-bottom: 24px;
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 12px;
    user-select: none;
    color: $darkThemeDark2Text;
  }
}

.metronomeButton {
  margin-right: 0;
  justify-self: flex-end;
  background-color: $secondaryGray3;

  svg path {
    @include transition(all);
  }

  &.active {
    background-color: $secondaryBlue5;

    svg path:not([data-fill-active='true']) {
      stroke: $darkThemeText;
    }

    svg path[data-fill-active='true'] {
      fill: $darkThemeText;
    }
  }
}
