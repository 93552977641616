@import '~/styles/variables';

.container {
  position: fixed;
  top: 72px;
  left: 0;
  height: 48px;
  z-index: 2;
  width: fit-content;
  max-width: 100vw;
  @include bigDesktop {
    top: 90px;
  }
  * {
    user-select: none;
  }
}

.list,
.simpleList {
  height: 100%;
  display: flex;
  width: fit-content;
  max-width: 100%;
  @include mobile {
    width: 100%;
    flex-direction: column;
    overflow: auto;
    &,
    & * {
      touch-action: pan-y;
    }
  }
}

.scrollContent {
  height: 100%;
}
