@import '~/styles/variables';

.scroll {
  flex-direction: column-reverse;
  display: flex;
  overflow: auto;
  flex-shrink: 1;
  flex-grow: 1;
  @include scroll-bar();
}

.container {
  display: flex;
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  padding-top: 5px;

  div[data-block='true'] {
    position: relative;
    margin-bottom: 8px;
  }

  :global(.DraftEditor-root) {
    width: 100%;
    color: $darkThemeText;
    font-size: 14px;

    :global(.DraftEditor-editorContainer) {
      height: 100%;
    }

    :global(.public-DraftEditor-content) {
      flex-grow: 1;

      & > div[data-contents='true'] {
        padding-bottom: 12px;
      }

      &,
      & * {
        font-size: 14px;
        line-height: 20px;
      }
    }

    :global(.public-DraftEditorPlaceholder-root) {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: $darkThemeDarkText;
      margin-top: 1px;
    }

    .activeBlock {
      position: relative;
      &:before {
        @include pseudo-full();
        background: $secondaryYellow5_35;
        border-radius: 4px;
        margin: -2px;
        padding: 2px;
      }
      &.withContent {
        max-width: max-content;
      }
    }
  }
}

.linesInput {
  background: none;
  color: $darkThemeText;
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  flex-grow: 1;
  padding: 0;

  &::placeholder {
    color: $darkThemeDarkText;
  }

  &[data-highlighted='true'] {
    position: relative;
    background: $secondaryGray;
    margin: -2px;
    padding: 2px;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &[data-active='true'] {
    background: $secondaryYellow5_35;
    border-radius: 4px;
    position: relative;
    padding-left: 2px;
    margin-right: -2px;
    margin-left: -2px;
    padding-right: 2px;

    span::selection {
      background-color: transparent;
    }
  }

  * {
    font-weight: normal;
  }
}

.syllablesCounter {
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  width: 30px;
  align-items: flex-end;
  position: relative;

  .suggestionsButtonContainer {
    position: absolute;
    margin-right: -7px;
    margin-top: -5px;
  }

  .suggestionsButton {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(180deg, #f3c12f 0%, #ee9329 100%);
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      animation: icon-spin 0.3s ease-in-out;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.syllablesItem {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #b3b4b5;
  position: absolute;
  user-select: none;
  top: 0;

  &::selection {
    background: none;
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
