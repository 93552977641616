@import '~/styles/variables';

.timelineControls {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 2px 0 0 2px;
  overflow: hidden;
  background: $darkTheme;

  .button {
    pointer-events: none;

    width: 40px;
    height: 40px;
    background: $darkTheme;
    cursor: initial;

    display: flex;
    justify-content: center;
    align-items: center;
    @include transition(all);

    svg {
      width: 16px;
      height: 16px;

      path {
        stroke: $darkThemeDark2Text;
        @include transition(all);
      }
    }

    &.left {
      svg {
        transform: rotate(90deg);
      }
    }

    &.right {
      svg {
        transform: rotate(-90deg);
      }
    }

    &:hover {
      background: $gray30;
    }

    &.active {
      pointer-events: all;
      cursor: pointer;

      svg {
        path {
          stroke: $darkThemeText;
        }
      }
    }
  }

  .divider {
    width: 2px;
    height: 40px;
    background: rgba(#595d62, 0.16);
  }
}
