@import '~/styles/variables';

.container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $darkThemeLight;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 150;
  padding-bottom: 40px;
}

.loader {
  width: 180px;
  position: relative;
  margin-top: 24px;
  & > div {
    height: 4px;
    background: linear-gradient(103.32deg, #3d4ff7 6.46%, #c145ed 98.38%);
    border-radius: 1000px;
    animation: loading 1s infinite ease-in-out;
    position: absolute;
  }
}

.footer {
  font-size: 14px;
  font-weight: 500;
  color: $darkThemeDark2Text;
  position: absolute;
  left: auto;
  right: auto;
  bottom: 24px;
}

@keyframes loading {
  0% {
    width: 0;
    left: 0;
  }
  35% {
    width: 180px;
    left: 0;
  }
  70% {
    left: 180px;
    width: 0;
  }
  71% {
    width: 0;
  }
}
