@import '~/styles/variables';

.container {
  align-items: center;
  border-radius: 50%;
  color: $circleButtonDisabledColor;
  cursor: not-allowed;
  display: flex;
  -webkit-tap-highlight-color: transparent;
  @include bgTransition($secondaryGray2, $secondaryGray);

  .icon {
    color: $circleButtonDisabledColor;
    margin: auto;
  }

  &.enabled {
    cursor: pointer;
    opacity: 1;

    .icon {
      color: $circleButtonDefaultColor;
      transition: color 250ms ease-out;
    }
  }

  &.small {
    height: 44px;
    width: 44px;

    .icon {
      height: 20px;
      width: 20px;
    }
  }

  &.medium {
    height: 40px;
    width: 40px;
    svg {
      transform: scale(0.85);
    }
    @include bigDesktop {
      height: 48px;
      width: 48px;
      svg {
        transform: none;
      }
    }
  }

  &.large {
    height: 50px;
    width: 50px;
    svg {
      transform: scale(0.85);
    }
    @include bigDesktop {
      height: 60px;
      width: 60px;
      svg {
        transform: none;
      }
    }
  }
}

.loader {
  animation: linear rotate 2s infinite;
  margin-left: auto;
  margin-right: auto;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
