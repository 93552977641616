@import '~/styles/variables';

.triggerButton {
  width: 46px;
  @include bgTransition($secondaryGray14, $secondaryGray);
  border-radius: 46px;
  flex-shrink: 0;
  svg {
    height: 24px;
  }
  span {
    font-size: 20px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
}

.triggerSelect {
  width: 203px;
  position: relative;
  @include bgTransition($secondaryGray14, $secondaryGray);
  margin-right: auto;
  padding: 0 12px;
  height: 46px;
  border-radius: 46px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  &.noOpen {
    padding: 0 24px 0 12px;
  }

  .selectPickerIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    min-width: 30px;
    width: 30px;
    border-radius: 30px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .selectPickerContent {
    display: flex;
    flex-direction: column;
    gap: 4px;

    label {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      color: $editableTextDefaultColor;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: $darkThemeText;
    }
  }

  & > svg {
    width: 18px;
    height: fit-content;
    margin-left: auto;
    @include transition(all);

    &[data-rotated='true'] {
      transform: rotate(180deg);
    }
  }
}

.menu {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 62px;
  .bg {
    backdrop-filter: blur(2px);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;

    &.left {
      background: linear-gradient(90deg, #1d1e21 -1.55%, rgba(29, 30, 33, 0.5) 100%);
    }

    &.right {
      background: linear-gradient(270deg, #1d1e21 -1.55%, rgba(29, 30, 33, 0.5) 100%);
    }

    &.top {
      background: linear-gradient(180deg, #1d1e21 -1.55%, rgba(29, 30, 33, 0.5) 100%);
    }

    &.bottom {
      background: linear-gradient(0deg, #1d1e21 -1.55%, rgba(29, 30, 33, 0.5) 100%);
    }
  }
  .content {
    position: absolute;
    z-index: 1;
    right: 16px;
    background: none;
    gap: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 4px;
      margin-top: 8px;
      padding-right: 4px;
    }
    .option {
      cursor: pointer;
      padding: 10px 14px;
      @include bgTransition($darkThemeLight, $secondaryGray);
      border: 1px solid $secondaryGray;
      border-radius: 28px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      white-space: nowrap;
      color: $darkThemeText;
      @include transition(all);
      &[data-selected='true'] {
        background: $secondaryBlue3;
        border-color: $secondaryBlue3;
      }
    }
  }
}

.menu.iconText {
  margin-top: 24px;

  .bg {
    width: 100%;
  }
  .label {
    display: none;
  }
  .content {
    .option {
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
}
