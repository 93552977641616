@import '~/styles/variables';

.timelineContainer {
  display: flex;
}

.timelineWrapper {
  width: calc(100% - 200px);
  overflow: hidden;

  @include mobile {
    transition: all 0.25s 0.25s ease-in-out;
    width: 100%;
    & > div {
      height: 100%;
    }
    &[data-empty='true'] {
      width: 101%;
    }
    &.configHidden {
      transition: all 0.25s ease-in-out;

      width: calc(100% - 3px);
    }
  }
}

.drumsContainer {
  display: flex;
  padding-top: 3px;
}

.hoveredChord {
  @include nonMobile {
    & > div {
      border-color: $secondaryGreen2;
    }
  }
}

.loadingChord {
  @include shimmer(2.2s);
  background-image: linear-gradient(to right, $secondaryGray 8%, $secondaryGray3 45%, $secondaryGray 90%);

  &[data-active='true'] {
    @include shimmer(4.5s);
  }
}

.tileSkeleton {
  width: 100%;
  min-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  & > div:first-child {
    width: 100%;
    max-width: 96px;
    height: 20px;
    background: $secondaryGray;
    border-radius: 4px;
    @include mobile {
      width: 60px;
      max-width: 100%;
    }
  }

  & > div:last-child {
    width: 66%;
    max-width: 64px;
    height: 16px;
    background: $secondaryGray;
    opacity: 0.5;
    border-radius: 4px;
    @include mobile {
      margin-left: 6px;
      width: 48px;
    }
  }
}

.editMenuTrigger {
  height: 140px;

  position: absolute;
  left: 0;
  right: 0;
  top: -100px;

  @include tablet {
    display: none;
  }
}
