@import '~/styles/variables';

.mobileControl {
  @include nonMobile {
    cursor: pointer;
    user-select: none;
  }
  @include mobileSwipeControl();
}

.openArrow,
.closeBtn {
  position: absolute;
  width: 122px;
  height: 20px;
  cursor: pointer;
  left: calc(50% - 61px);
  display: flex;
  justify-content: center;
  align-items: center;
  top: -19px;
  touch-action: none;

  .arrow {
    width: 18px;
    height: 18px;
    transform: scale(1, -1);
    @include transition(transform);
  }

  .bg {
    stroke: $secondaryGray;
    fill: $darkTheme;
    position: absolute;
    left: 0;
    top: 0;
  }

  &[data-open='true'] {
    .arrow {
      transform: scale(1);
    }
  }
}

.closeBtn {
  width: 98px;
  left: 0;

  .arrow {
    position: absolute;
    left: 27px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.heightChangeMode {
  cursor: ns-resize;
  align-items: flex-end;
}

.handle {
  width: 40px;
  height: 4px;
  border-radius: 40px;
  background: $grey10;

  z-index: 1;
  margin-bottom: 5px;
}
