@import '~/styles/variables.scss';

.mobileTab {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  bottom: 64px;

  padding: 20px 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  background: $darkThemeLight;

  z-index: 1;

  overflow: hidden;

  @include transition(all);

  &.active {
    top: 105px;

    overflow-x: hidden;
    overflow-y: auto;
    &,
    & * {
      touch-action: pan-y;
    }
  }

  &.isFooterHidden {
    bottom: 51px;
    transition: none;
  }

  &.lyrics {
    gap: 20px;

    &,
    & * {
      touch-action: auto;
    }
  }

  &.settings {
  }
}
