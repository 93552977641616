@import '~/styles/variables.scss';

.wrapper {
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  gap: 24px;

  z-index: 3;

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;

    color: $darkThemeText;
  }

  .illustration {
    width: 280px;
  }

  .btnsContainer {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 12px;

    .btn {
      flex: 1;

      padding: 12px;

      border: 1px solid $secondaryGray16;

      font-size: 14px;
      font-weight: 600;
      line-height: 18px;

      color: $darkThemeText;

      border-radius: 4px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      &.primary {
        background: linear-gradient(103.32deg, $secondaryBlue12 6.46%, $secondaryBlue13 98.38%);

        border: none;
      }
    }
  }
}
