@import '~/styles/variables';

.outerWrapper {
  transition: all 0.25s 0.25s ease-in-out;
  margin-top: 40px;
  padding-top: 4px;
  border-top: 1px solid #2e2f30;

  display: flex;
  flex-direction: column;
  gap: 1px;

  @include mobile {
    position: absolute;
    width: 48px;
    flex-shrink: 0;
    top: 40px;
    margin: 0;

    &[data-mobile-timeline-hidden='false'] {
      z-index: 2;
    }
  }
}

.wrapper {
  height: $tileHeightSmall;

  display: flex;
  flex-direction: row;

  @include bigDesktop {
    height: $tileHeight;
  }
  @include mobile {
    height: $tileHeightMobile;
  }

  .container {
    width: 120px;
    position: relative;
    height: 100%;
    background-color: $gray20;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    padding: 8px;

    @include mobile {
      padding: 0;
      width: 48px;
      align-items: center;
      background: transparent;
    }
  }

  &.skeleton {
    pointer-events: none;

    .container {
      width: 200px;
      background-color: $darkTheme;
      align-items: center;
      padding: 12px 7px 8px;

      .instrumentIconsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        min-height: 12px;
      }
    }

    p {
      height: 16px;
      width: 105px;
      background: $secondaryGray;
      opacity: 0.5;
      border-radius: 4px;
    }
  }

  @include mobile {
    height: $tileHeightMobile;
    margin-top: 0;
    margin-right: 0;
    background-color: $darkThemeLight;
    padding: 8px 4px;
    background: $gray20;

    &.chords,
    &.drums {
      .container {
        padding: 0;
        border-radius: 0 4px 4px 0;
        transition: background-color 0.25s 0.25s ease-in-out, padding 0.25s ease-in-out, border-radius 0.25s ease-in-out;

        & * {
          transition: all 0s ease-in-out;
          opacity: 0;
        }
      }
    }

    &.chords {
      .container {
        background: #3f9bff;
      }
    }

    &.drums {
      .container {
        background: #0abb7b;
      }
    }
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.loader {
  width: 24px;
  animation: linear rotate 2s infinite;
  margin-left: auto;
  margin-right: auto;
}
.instrumentIconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}
.iconContainer {
  position: relative;

  width: 24px;
  height: 24px;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: $dark30;
  @include transition(all);

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: $secondaryGray2;
  }

  @include mobile {
    width: 40px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.title {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  color: $secondaryGray32;
  margin: 0;
}
.volumeControl {
  @include mobile {
    display: none !important;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.layerIcon {
  width: 16px;
  height: 16px;

  &.blue {
    color: #457eed;
  }

  &.yellow {
    color: #f2a642;
  }

  &.purple {
    color: #b966e0;
  }

  &.pink {
    color: #ef5da8;
  }

  &.cyan {
    color: #56ccf2;
  }

  &.lagoon {
    color: #23abbe;
  }

  &.green {
    color: $secondaryGreen9;
  }
}

.hideDrumsTrigger {
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;
  border-radius: 4px;
  @include transition(all);

  &:hover {
    background: #2b2e33;
  }

  svg {
    width: 13px;
  }
}

.numOfLayers {
  width: 20px;
  height: 20x;
  border-radius: 50%;

  position: absolute;
  top: -8px;
  right: -4px;

  background: $dark20;
  border: 1px solid $secondaryGray39;

  font-size: 12px;
  font-weight: 500;
  color: $darkThemeText;

  display: flex;
  justify-content: center;
  align-items: center;
}
