@import '~/styles/variables';

.container {
  position: relative;
  width: 100%;
  order: 0;

  padding-bottom: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  touch-action: pan-x;
  border: none;

  @include bigDesktop {
    padding-bottom: 0;
  }
}
.showRomanNumerals {
  font-size: 16px;
}
.tileScale {
  position: absolute;

  top: -3px;
  left: 50%;
  transform: translate(-50%, -100%);
}
.tileName {
  width: 100%;

  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  text-align: center;

  color: $tileTextColor;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tileDegree {
  display: block;

  font-weight: 400;
  font-size: 14px;
  color: $tileTextColor;

  text-overflow: ellipsis;
  overflow: hidden;
}

.addTile {
  cursor: pointer;
}

.loader {
  width: 26px;
  animation: linear rotate 2s infinite;
  margin-left: auto;
  margin-right: auto;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tile {
  position: relative;
  order: 0;
  outline: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  max-height: $tileHeightSmall;
  min-height: $tileHeightSmall;

  background-color: $gray20;
  user-select: none;

  position: relative;
  flex: 0 0 auto;

  margin: 0 1px;

  border-radius: 8px;
  border: 2px solid #2b2e33;

  transition: background 250ms ease-out, background-color 0.25s ease, width 0s, border-color 0s;

  @include mobile {
    max-height: $tileHeightMobile;
    min-height: $tileHeightMobile;
  }

  @include bigDesktop {
    max-height: $tileHeight;
    min-height: $tileHeight;
  }

  &.skeletonMode {
    pointer-events: none;
    cursor: auto;
  }

  &.expanding {
    cursor: move !important;
  }

  &.active {
    cursor: pointer !important;
  }

  @mixin showEdit {
    opacity: 1 !important;
  }
  @mixin showExtender {
    opacity: 0.7;
  }

  &:not(.moving):hover .hoverShow,
  &.expanding .hoverShow {
    @include showEdit();
  }

  &:not(.dragging).selected .hoverShow {
    @include showEdit();
  }

  &:hover:not(.moving):not(.drum) {
    background-color: $secondaryGray;
  }

  &.selected {
    z-index: 1;

    background: linear-gradient(103deg, #3d4ff7, #457eed 50%, #3d4ff7) var(--x, 0) / 200%;
    background-color: $gray20;
    @include nonMobile {
      &:hover {
        --x: 100%;
      }
    }
    border-color: $lightTheme;
    div[data-skeleton='true'] {
      background-color: #5a7fff;
    }

    &.expanding,
    &.draggable {
      --x: 100%;
    }

    .dragHandle {
      svg path {
        fill: $darkThemeText;
      }
    }

    .extenderContainer {
      display: flex;
    }
  }

  &:not(:hover) {
    @include nonTablet {
      .dragHandle {
        display: none;
      }
    }
  }

  &.dragging {
    background: repeating-linear-gradient(
      45deg,
      $secondaryGray,
      $secondaryGray 10px,
      rgba(38, 39, 42) 10px,
      rgba(38, 39, 42) 20px
    ) !important;

    .container {
      color: #636363;
    }
    .magicChord,
    .dragHandle,
    .lockButton,
    .edit {
      display: none;
    }
  }

  &.loading {
    @include shimmer(3s);
    background-image: linear-gradient(to right, $secondaryGray2 8%, $secondaryGray3 45%, $secondaryGray2 90%);
  }

  &.active.loading {
    @include shimmer(4.5s);
  }

  &.replaced {
    border: none !important;
    background: transparent !important;
  }
}

.drum {
  & > div {
    height: 100%;
  }
  @include transition(border-color);
  &.empty {
    background-color: $darkTheme;
  }
  .container {
    width: 100%;
  }

  &[data-loading='true'] {
    @include shimmer(2.2s);
    background-image: linear-gradient(to right, $secondaryGray 8%, $secondaryGray3 45%, $secondaryGray 90%);
  }
}

.magicChord,
.lockButton,
.edit {
  margin: 0;
  opacity: 0 !important;
  position: absolute;
}

.magicChord {
  top: 5px;
  left: 10px;
  background: rgba(255, 255, 255, 0);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: all 250ms ease-out !important;
  z-index: 2;
  svg {
    width: 22px;
    height: 22px;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  @include nonMobile {
    &.small {
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      width: 24px;
      height: 24px;
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  @include mobile {
    width: 28px;
    height: 28px;
    svg {
      width: 18px;
      height: 18px;
    }
    &.small {
      left: 50%;
      transform: translateX(-50%);
      top: 4px;
      width: 24px;
      height: 24px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.edit {
  bottom: 4px;
  left: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  @include transition(all);

  @include mobile {
    display: none;
  }
  svg {
    width: 18px;
    height: 18px;
  }
  @include nonMobile {
    &.small {
      display: none;
    }
  }
}

.lockButton {
  top: 5px;
  right: 10px;
  background: rgba(255, 255, 255, 0);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: all 250ms ease-out !important;

  @include mobile {
    width: 18px;
    height: 18px;
    background: none;
    transition: none !important;
  }

  svg {
    width: 22px;
    height: 22px;
    color: $grey10 !important;
    @include mobile {
      width: 18px;
      height: 18px;
    }
  }
  &:hover {
    background: rgba(255, 255, 255, 0.2);
    svg {
      color: $darkThemeText !important;
    }
  }
  &.locked {
    opacity: 1 !important;
  }
  &.small {
    right: 50%;
    transform: translateX(50%);

    width: 24px;
    height: 24px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.tile.selected {
  .lockButton {
    svg {
      color: $darkThemeText !important;
    }
  }
}

.dragHandle {
  position: absolute;
  left: auto;
  right: auto;
  border-radius: 2px;
  padding: 3px 12px;
  bottom: 2px;
  @include transition(all);

  @include bigDesktop {
    bottom: 4px;
  }

  &.small {
    padding: 4px 6px;
  }
  @include mobile {
    padding: 2px 10px;
    &.small {
      padding: 2px 6px;
    }
  }
}

@keyframes popinDeg {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 0.8;
    transform: scale(1, 1);
  }
}

@keyframes popin {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.addIcon {
  width: 26px;
}

.buttons {
  display: flex;
  gap: 8px;
  padding: 16px;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  @include mobile {
    padding: 8px;
    flex-direction: column-reverse;
  }
  .addIcon,
  .generateIcon {
    width: 26px;
    max-width: 50%;
  }
  .addButton {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $secondaryGray15;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: $secondaryGray;
    }

    &[data-loading='true'] {
      @include shimmer(2.3s);
      background-image: linear-gradient(to right, $secondaryGray15 8%, $secondaryGray 45%, $secondaryGray15 90%);
    }
    @include mobile {
      width: unset;
      height: unset;
    }
    .addButtonCenter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $lightTheme;
        @include mobile {
          display: none;
        }
      }
    }
  }
  .generateButton {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($secondaryGreen, 0.08);
    border-radius: 4px;
    cursor: pointer;
    @include mobile {
      width: unset;
      height: unset;
      grid-row: 1;
    }
    &[data-loading='true'] {
      @include shimmer(2.3s);
      background-image: linear-gradient(
        to right,
        rgba($secondaryGreen, 0.08) 8%,
        rgba($secondaryGreen, 0.15) 45%,
        rgba($secondaryGreen, 0.08) 90%
      );
    }
    .generateButtonCenter {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $secondaryGreen3;
        @include mobile {
          display: none;
        }
      }
      svg path {
        fill: $secondaryGreen3;
      }
    }
  }
}

.greenBorder {
  @include nonMobile {
    border-color: $secondaryGreen;
  }
}

.extenderDrum {
  opacity: 0.7;
  z-index: 2;
}

.extenderContainer {
  display: none;

  position: absolute;

  width: 20px;
  top: -2px;
  bottom: -2px;
  border: 0 solid $lightTheme;
  border-bottom-width: 2px;
  border-top-width: 2px;

  flex-direction: column;
  justify-content: space-between;

  z-index: 1;

  .extender {
    height: 34px;

    cursor: move;

    &.top {
      position: absolute;
      top: -2px;
      right: -3px;
      transform: translateX(50%);
    }

    &.bottom {
      position: absolute;
      bottom: -2px;
      right: -6px;
    }
  }

  &.right {
    right: -6px;
    border-right-width: 6px;
    border-radius: 0 8px 8px 0;

    align-items: flex-end;
  }

  &.left {
    left: -6px;
    border-left-width: 6px;
    border-radius: 8px 0 0 8px;

    align-items: flex-start;

    .top {
      right: unset;
      left: -3px;
      transform: translateX(-50%);
    }
  }
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;

  & > div:nth-child(1) {
    margin-left: 0;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
}

.transitionAdd {
  position: absolute;
  top: 0;
  left: -3px;
  transform: translateX(-50%);

  opacity: 0;
  z-index: -1;

  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: $dark25;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 500ms ease-in-out, opacity 200ms ease-in-out;

  svg {
    width: 20px;
  }

  &.isFirst {
    left: -8px;
    transform: none;
  }

  &.shown {
    transition: all 500ms ease-in-out, opacity 250ms 250ms ease-in-out;
    top: -34px;
    opacity: 1;
    z-index: 1;
  }
}

.draftIcon {
  width: 26px;
}
.previewIndicator {
  position: absolute;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);

  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: $lightTheme;
  opacity: 1;

  @include transition(all);
}
.previewIndicatorHidden {
  opacity: 0;
}

.limitIcon {
}
