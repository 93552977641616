@import '~/styles/variables';

.container {
  position: fixed;
  left: 0;
  margin-left: 8px;
  bottom: 98px;
  width: 360px;
  background: $darkTheme;
  border: 1px solid $secondaryGray;
  border-radius: 4px;
  padding: 20px 60px 20px 25px;
  display: flex;
  align-items: center;
  color: $darkThemeText;
  font-size: 16px;
  line-height: 20px;
  z-index: 250;
  @include transition(left);
  svg {
    margin-right: 8px;
  }
  span {
    position: absolute;
    right: 25px;
    height: 26px;
    cursor: pointer;
    @include mobile {
      right: 8px;
      height: 20px;
    }
    svg {
      margin-right: 0;
      width: 26px;
      height: 26px;
      @include mobile {
        width: 20px;
        height: 20px;
      }
      path {
        @include transition(stroke);
      }
    }
    &:hover {
      svg path {
        stroke: $darkThemeText;
      }
    }
  }
  @include mobile {
    bottom: auto;
    top: 20px;
    width: 220px;
    padding: 20px 30px 20px 16px;
    font-size: 14px;
    line-height: 16px;
  }
}
