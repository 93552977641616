@import '~/styles/variables.scss';

.wrapper {
  position: relative;

  height: 100%;
  max-height: 100%;

  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  gap: 24px;

  overflow: hidden;

  .header {
    width: 100%;
    min-height: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 12px;

    .top {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;

        color: $darkThemeText;
      }

      .settings {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 10px;

        .aiModeSelect {
          width: 34px;
          height: 34px;

          z-index: 3;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .bottom {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      gap: 8px;

      .text {
        min-width: fit-content;

        font-size: 12px;
        font-weight: 600;
        line-height: 15px;

        color: $secondaryGray16;
      }

      .btn {
        flex: 1;

        height: 32px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        background: $secondaryGray29;

        border-radius: 4px;

        font-size: 12px;
        font-weight: 600;
        line-height: 15px;

        @include transition(all);

        cursor: pointer;

        &.active {
          background: $secondaryBlue16;
        }
      }
    }
  }

  .content {
    position: relative;

    width: 100%;
    height: 100%;

    max-height: 100%;

    display: flex;
    flex-direction: column;
    gap: 12px;

    overflow-x: hidden;
    overflow-y: auto;

    padding-bottom: 32px;

    @include scroll-bar();

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;

      color: $darkThemeText;

      padding: 0;
      margin: 0;

      &.title {
        font-weight: 600;
        line-height: 20px;

        color: $darkThemeDark2Text;
      }
    }

    --mask: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 0,
        rgba(0, 0, 0, 1) calc(100% - 32px),
        rgba(0, 0, 0, 0) 100%,
        rgba(0, 0, 0, 0) 0
      )
      100% 50% / 100% 100% repeat-x;

    -webkit-mask: var(--mask);
    mask: var(--mask);
  }

  .btnsContainer {
    width: 100%;
    min-height: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 12px;

    .btn {
      position: relative;

      height: 50px;

      flex: 1;

      padding: 12px;

      border: 1px solid $secondaryBlue16;

      font-size: 14px;
      font-weight: 600;
      line-height: 18px;

      color: $darkThemeText;

      border-radius: 4px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      gap: 8px;

      opacity: 0.6;

      cursor: initial;

      &.primary {
        background: $secondaryBrown2;

        border: none;
        color: $secondaryYellow;
      }

      &.active {
        opacity: 1;
        filter: none;

        cursor: pointer;
      }

      .regenerate {
        width: 20px;

        path {
          fill: $secondaryYellow;
        }
      }

      .useIt {
        width: 20px;
      }

      .btnInner {
        display: flex;
        flex-direction: column;

        .subInfo {
          opacity: 0.6;
        }
      }

      .stars {
        width: auto;

        position: absolute;
        left: 5px;
        top: 50%;
        right: 5px;
        bottom: 5px;

        transform: translateY(-50%);
      }
    }

    @include nonMobile {
      margin-top: auto;
    }
  }
}

.blurMenu {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  &:empty {
    display: none;
  }
  &.force {
    display: flex;
    justify-content: center;
    align-items: center;

    background: $secondaryGray27_30;
    backdrop-filter: blur(3px);

    z-index: 3;
  }
}

.skeleton {
  height: 20px;
  width: 100%;

  border-radius: 4px;

  @include shimmer(2.2s);
  background-image: linear-gradient(to right, $secondaryGray3 8%, $secondaryGray 45%, $secondaryGray3 90%);
}
