// RESPONSIVE
$minLargeDesktopWidth: 1281px;
$maxDesktopWidth: 1280px;
$minDesktopWidth: 769px;
$maxTabletWidth: 768px;
$minTabletWidth: 481px;
$maxMobileWidth: 480px;
$minMobileWidth: 320px;

// COLORS

// global
$darkTheme: #1d1e21;
$darkThemeLight: #17171a;
$darkThemeText: #ffffff;
$darkThemeText_20: #ffffff33;

$lightTheme: #ffffff;
$lightThemeText: #1d1e21;
$lightThemeText2: #26272a;

$darkThemeDarkText: #6e6e6f;
$darkThemeDark2Text: #8b8c8e;

$secondaryBlack: #000000;
$secondaryBlack2: #111113;
$secondaryBlack3: #1e1e23;
$secondaryBlack4: #202025;
$secondaryGray: #36373c;
$secondaryGray2: #404349;
$secondaryGray3: #26272a;
$secondaryGray3_20: #26272a80;
$secondaryGray4: #101113;
$secondaryGray5: #a8a9aa;
$secondaryGray6: #5d6066;
$secondaryGray8: #54575f;
$secondaryGray9: #2f2e31;
$secondaryGray10: #f8f9fb;
$secondaryGray11: #dbdde6;
$secondaryGray12: #edeef2;
$secondaryGray13: #f1f2f6;
$secondaryGray14: #27292d;
$secondaryGray15: #303135;
$secondaryGray16: #b3b4b5;
$secondaryGray17: #44454b;
$secondaryGray18: #444850;

$secondaryGray20: #444549;
$secondaryGray21: #33373d;
$secondaryGray22: #303236;
$secondaryGray23: #434349;
$secondaryGray24: #28292d;
$secondaryGray25: #232329;
$secondaryGray26: #292a2e;
$secondaryGray27: #17181b;
$secondaryGray27_30: #17181bd6;
$secondaryGray28: #3d3e40;
$secondaryGray29: #303039;
$secondaryGray30: #ebebeb;
// $secondaryGray31: #;
$secondaryGray32: #e3e3e2;

$gray20: #252629;
$gray25: #313235;
$gray30: #333438;

$grey10: #8b8c8e;
$grey30: #595d62;

$dark10: #141618;
$dark15: #292a2d;
$dark20: #252629;
$dark23: #2b2d30;
$dark25: #2b2e33;
$dark30: #333438;

$yellow40: #f2a642;

$secondaryGray35: #b9b9b9;
$secondaryGray36: #7c7d7e;
$secondaryGray37: #141618;
$secondaryGray38: #f4f4f8;
$secondaryGray39: #3b3b3b;

$secondaryGreen: #0abb7b;
$secondaryGreen_25: #0abb7b40;
$secondaryGreen2: #18d28f;
$secondaryGreen3: #13b279;
$secondaryGreen4_16: #1ce19b29;
$secondaryGreen4_24: #1ce19b3d;
$secondaryGreen4_30: #1ce19b4d;
$secondaryGreen4: #3fa37f;
$secondaryGreen5: #24df9c;
$secondaryGreen6: #1bd190;
$secondaryGreen7: #04ffa5;
$secondaryGreen8: #1da373;
$secondaryGreen9: #1da574;
$secondaryGreen9_16: #1da57452;
$secondaryBlue: #357aff;
$secondaryBlue2: #86adff;
$secondaryBlue3: #2c70f5;
$secondaryBlue4: #9cbcff;
$secondaryBlue5: #007aff;
$secondaryBlue5_16: #007aff29;
$secondaryBlue5_24: #007aff3d;
$secondaryBlue6: #5c80ff;
$secondaryBlue7_20: rgba(79, 174, 194, 0.2);
$secondaryBlue8: #3f9bff;
$secondaryBlue9: #67b0ff;
$secondaryBlue10: #68b0ff;
$secondaryBlue11: #57c3ff;
$secondaryBlue12: #3d4ff7;
$secondaryBlue13: #457eed;
$secondaryBlue13_16: #457eed29;
$secondaryBlue14: #5b9dff;
$secondaryBlue15: #3f72fd;
$secondaryBlue16: #5a7fff;
$secondaryBlue17: #7bb8ff;
$secondaryBlue18: #c2d7ff;
$secondaryBlue19: #1d2749;
$secondaryBlue20: #3e72fd;
$secondaryYellow: #f3c12f;
$secondaryYellow2: #fad846;
$secondaryYellow3: #dfb341;
$secondaryYellow4: #eeb930;
$secondaryYellow4_20: rgba(238, 185, 48, 0.2);
$secondaryYellow5: #edb930;
$secondaryYellow5_35: rgba(237, 185, 48, 0.35);
$secondaryYellow6: #887543;
$secondaryYellow7: #6c5a2c;
$secondaryYellow8: #f1ac2d;
$secondaryRed: #e94f66;
$secondaryRed2: #ee325f;
$secondaryPurple: #a92cf5;
$secondaryPurple2: #b524e8;
$secondaryPurple3: #c145ed;
$secondaryPurple4: #b85df0;
$secondaryBrown: #413a23;
$secondaryBrown2: #45402f;
$secondaryBrown2: rgba(136, 104, 21, 0.44);
$secondaryBrown3: rgba(136, 104, 21, 0.25);

// components/common/IconButton
$iconButtonDisabledColor: #adb2b8;
$iconButtonDefaultColor: $darkThemeText;

$iconButtonHoverColor: #4a4f53;
$iconButtonHoverBlueColor: #2d86e8;

// components/common/RoundedButton
$roundedButtonDefaultColor: #8e959d;
$roundedButtonDefaultHoverColor: #bac1c9;
$roundedButtonGradientColorA: #2b79ea;
$roundedButtonGradientColorB: #5f42f3;
$roundedButtonShadowColor: #0000002c;

// components/common/IconMenuButton
$iconMenuButtonDefaultColor: $darkThemeText;

// components/common/CircleIcon
$circleButtonDisabledColor: #e0e0e0;
$circleButtonDefaultColor: #8e959d;
$circleButtonHoverBlueColor: #57a2ef;

// components/common/CenterModal
$centerModalBackgroundColor: #ffffff;
$centerModalHeadBorderColor: #e8e8e8;
$centerModalTintLightColor: #ffffff44;
$centerModalTintDarkColor: #00000014;
$centerModalShadowColor: #0000001f;

// components/common/EditableText
$editableTextDefaultColor: #8b8c8e;
$editableTextIconColor: #8b8c8e;

// components/common/Picker
$pickerDefaultColor: #d9d9d9;
$pickerTextColor: #4d4d4d;
$pickerRoundedTextColor: #5d5d5d;
$pickerHoverBlueColor: #57a2ef;
$pickerDefaultArrowColor: #969696;

// common/Timeline
$timelineDarkMutedColor: #363636;
$timelineLightMutedColor: #aeaeaf;

// common TimelineTile
$tileTextColor: $darkThemeText;

$tileHeight: 108px;
$tileHeightSmall: 108px;
$tileHeightMobile: 108px;

// everything Blog related
$blogTitleColor: #333333;
$blogHeaderColor: $secondaryGray;
$blogTextColor: #333333;
$blogTextLinkColor: #4f60fa;
$blockDesktopWidth: 640px;

@mixin no-scroll-bar() {
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin scroll-bar() {
  &::-webkit-scrollbar-thumb {
    height: 73px;
    width: 24px;
    background-clip: padding-box;
    border-radius: 15px;
    border: 8px solid rgba(0, 0, 0, 0);
    background: $secondaryGray;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: $darkTheme;
    height: 3px;
    width: 3px;
  }
}

@mixin light-scroll-bar() {
  &::-webkit-scrollbar-thumb {
    height: 73px;
    width: 24px;
    background-clip: padding-box;
    border-radius: 15px;
    border: 8px solid rgba(0, 0, 0, 0);
    background: $secondaryGray5;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: $secondaryGray10;
    height: 3px;
    width: 3px;
  }
}

@mixin tablet {
  @media (max-width: 1100px) {
    @content;
  }
}

@mixin smallTablet {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin nonTablet {
  @media (min-width: 1101px) {
    @content;
  }
}

@mixin smallMobileWidth {
  @media (max-width: 385px) {
    @content;
  }
}
@mixin smallMobile {
  @media (max-height: 750px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin nonMobile {
  @media (min-width: 577px) {
    @content;
  }
}

@mixin bigDesktop {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin transition($param) {
  transition: $param 250ms ease-out;
}

@mixin gradientBgTransition($c1: '#ffffff', $c2: '#000000') {
  @include transition(all);
  background: linear-gradient(103deg, $c1, $c2 50%, $c1) var(--x, 0) / 200%;
  @include nonMobile {
    &:hover {
      --x: 100%;
    }
  }
}
@mixin gradientBgTransitionWithBorder(
  $c1: '#ffffff',
  $c2: '#000000',
  $c3: '#000000',
  $opacity: 50%,
  $borderWidth: 1px
) {
  background: linear-gradient(100deg, rgba($c1, $opacity) 25%, rgba($c2, $opacity) 75%) padding-box var(--x, 50%) / 200%,
    linear-gradient($c3, $c3) padding-box var(--x, 50%) / 200%,
    linear-gradient(100deg, $c1 25%, $c2 75%) border-box var(--x, 50%) / 200%;
  border: $borderWidth solid transparent;
  @include transition(all);
  @include nonMobile {
    &:hover {
      --x: calc(100% + #{$borderWidth});
    }
  }
}
@mixin gradientBgWithBorder($c1: '#ffffff', $c2: '#000000', $c3: '#000000', $borderWidth: 1px) {
  background: linear-gradient($c3, $c3) padding-box, linear-gradient(100deg, $c1, $c2) border-box;
  border: $borderWidth solid transparent;
}

@mixin bgTransition($c1, $c2) {
  @include transition(background-color);
  background-color: $c1;
  @include nonMobile {
    &:hover {
      background-color: $c2;
    }
  }
}

@mixin onboardingBg() {
  box-shadow: 0 0 0 max(100vh, 100vw) rgba(0, 0, 0, 0.7);
  z-index: 100;
  @include transition(box-shadow);
}

@mixin pseudo-full() {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

@mixin shimmer($duration: 2.2s) {
  animation-duration: $duration;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background-size: 1200px 100%;
}

@mixin mobileSwipeControl {
  width: 100%;
  position: absolute;
  top: -33px;
  height: 34px;
  overflow: hidden;
  touch-action: none;
  & > div {
    display: flex;
    justify-content: center;
    position: absolute;
    left: -2px;
    right: -2px;
    top: 3px;
    bottom: 0;
    background: $darkTheme;
    border: 1px solid $secondaryGray;
    border-bottom: none;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  & > div > div {
    width: 64px;
    margin-top: 13px;

    &:after {
      content: '';
      width: 100%;
      display: block;
      border-radius: 2px;
      height: 4px;
      background-color: $secondaryGray;
    }
  }
}

@mixin primaryGradient {
  background: linear-gradient(103.32deg, #3d4ff7 6.46%, #c145ed 98.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
