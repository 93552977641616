@import '~/styles/variables';

.container {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.repeatedDivider {
  width: 1px;
  z-index: 0;
  margin-left: -3px;
  border-left: 2px solid #3d4045;
  top: 20px;
  bottom: -4px;
  position: absolute;
}

.track {
  display: flex;
  position: relative;
  width: 100%;
  height: 9px;
  span {
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 2px;
    @include transition(all);

    &[data-placeholder='true'] {
      background: rgba(255, 255, 255, 0.2);
    }
    &[data-muted='true'] {
      background: #595d62;
    }

    &.DRUMS {
      background: $secondaryGreen4;

      &[data-repeated='true'],
      &[data-darken='true'] {
        background: rgba($secondaryGreen4, 0.35);
      }
      &[data-active='true'] {
        background: $secondaryGreen5;
      }
    }
    &.MELODY {
      background: $secondaryBlue13;

      top: 50%;
      transform: translateY(-50%);

      min-height: 2px;
      margin: 0;

      &[data-repeated='true'],
      &[data-darken='true'] {
        background: rgba($secondaryBlue13, 0.2);
      }
      &[data-active='true'] {
        background: $secondaryBlue13;
      }
    }
  }
}
