@import '~/styles/variables';

.dropdownMenu {
  z-index: 100;
  width: max-content;
  margin-left: auto;
  @include mobile {
    width: auto;
  }
  & > div:last-child {
    width: 285px;
    left: auto;
    right: 0;
    top: auto;
    bottom: calc(100% + 16px);
    @include mobile {
      margin-top: 0 !important;
      width: 100%;
      padding-left: 16px;
    }
  }

  &.lyricsOpen {
    transform: translateX(-386px);

    @include transition(all);

    @media (max-width: 1250px) {
      transform: translateX(-386px) translateY(-100px);

      z-index: -1;

      @media (max-height: 775px) {
        display: none;
      }
    }
  }
}

.launchpadButton {
  @include bgTransition(#2b2e33, $secondaryGray2);
  width: 185px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: auto;
  border-radius: 4px;
  span {
    color: $darkThemeText;
  }

  &.lyricsOpen {
    width: 40px;
    height: 40px;
    border-radius: 40px;

    @include transition(all);

    svg {
      width: 21px;
      height: 21px;
    }

    @media (max-width: 1250px) {
      width: 50px;
      height: 50px;
      border-radius: 50px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.dropdownOption {
  height: auto;
  padding-right: 16px;
  @include mobile {
    padding-bottom: 8px;
  }
  & > span {
    align-self: flex-start;
  }
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding-top: 8px;
    @include mobile {
      font-size: 16px;
      line-height: 20px;
    }
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    color: $darkThemeDark2Text;
    margin: 2px 0 0;
    padding-bottom: 8px;
    @include mobile {
      font-size: 14px;
      line-height: 18px;
    }
  }
  @include nonMobile {
    &:hover {
      background-color: #222326 !important;
      &:after {
        background-color: $secondaryGray11;
      }
    }
  }
  .noStroke path {
    stroke: none !important;
  }
  &.green {
    svg {
      color: $secondaryGreen;
    }
    svg path {
      fill: $secondaryGreen !important;
    }
    @include nonMobile {
      &:hover {
        background-color: #1c2621 !important;
        &:after {
          background: $secondaryGreen;
        }
      }
    }
  }
  &.yellow {
    svg path {
      stroke: $secondaryYellow3 !important;
    }
    @include nonMobile {
      &:hover {
        background-color: #29271e !important;
        &:after {
          background: $secondaryYellow3;
        }
      }
    }
  }
  &.blue {
    svg {
      color: $secondaryBlue6;
    }
    svg > path:not([data-fill-active='true']) {
      stroke: $secondaryBlue6 !important;
    }

    svg path {
      fill: $secondaryBlue6 !important;
    }

    svg mask {
      fill: $secondaryBlue6;
    }
    @include nonMobile {
      &:hover {
        background-color: #212532 !important;
        &:after {
          background: $secondaryBlue6;
        }
      }
    }
  }
  &.lightBlue {
    svg {
      color: $secondaryBlue8;
    }
    svg > path:not([data-fill-active='true']) {
      stroke: $secondaryBlue8 !important;
    }

    svg path {
      fill: $secondaryBlue8 !important;
    }

    svg mask {
      fill: $secondaryBlue8;
    }
    @include nonMobile {
      &:hover {
        background-color: #212532 !important;
        &:after {
          background: $secondaryBlue8;
        }
      }
    }
  }
  @include mobile {
    svg {
      width: 26px;
      height: 26px;
    }
  }
}
